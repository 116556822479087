.container{
    width: 100%;
    height: auto;
}

.inputContainer{
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 13px var(--Container, 16px);
    gap: 12px;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
}

.dateDisplay{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.inputFieldContainer{
    display: flex;
    align-items: center;
    flex: 1 0 0;
}

.icon{
    width: 22px;
    height: 22px;
}

.iconContainer{
    display: flex;
    padding: 13px;
    align-items: center;

    border-left: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.react-datepicker {
    width: 100% !important;
    max-width: 100% !important;
}

.react-datepicker__tether-element {
    width: 100% !important;
}

.input{
    border: 1px solid transparent;
    width: 424px;
}

.input:focus{
    border: 1px solid transparent;
    outline: none;
}

.calendarClass{
    z-index: 1;
    right: auto;
    top: 0
}