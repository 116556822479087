.container{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
    align-self: stretch;
    justify-content: center;
    width: 100%;
}

.mainModalContainer{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 131;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer{
    position: fixed;
    max-width: 1086px;
}

.textContainer{
    min-width: 40px;
    width: 100%;
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
}

.contentTypo{
    color: var(--icon-text-mediumemp, #524B58);
    text-align: center;

    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.progressBar{
    width: 60px;
    min-width: 40px;
    height: 3px;
}

.noDataContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    text-align: center;
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.contentIcon{
    width: 16px;
    height: 16px;
}