.container{
    width: auto;
    height: auto;

    display: inline-flex;
    padding: 1px 0px;
    align-items: flex-start;
}

.optionContainer{
    display: flex;
    padding: 0px var(--Primary, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    cursor: pointer;
}