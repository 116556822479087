
/* from here the css of the magange.html starts */
.action-plan-section {
 top: -900px;
 left: 152px;
 right: 16px;
 width: 1520px;
 height: 912px;
 border-radius: 20px;
 background: snow;
}

.manageActionPlanContainer{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.main-div-action-plan-section {
 display: flex;
 flex-direction: column;
}

.first-div-action-plan {
 width: 210px;
 height: 24px;
 margin-left: 32px;
 margin-top: 22px;
}

.first-span-action-plan {
 color: #2f4358;
 font-feature-settings: "clig" off, "liga" off;
 font-family: Founders Grotesk;
 font-size: 24px;
 font-style: normal;
 font-weight: 600;
 line-height: 24px;
}

.second-div-action-plan {
 width: 1450px;
 height: 215px;
 border-radius: 20px;
 border: 1.5px dashed #2f4358;
 background: linear-gradient(
     225deg,
     rgba(87, 239, 195, 0.1) 0%,
     rgba(193, 127, 242, 0.1) 51.26%,
     rgba(255, 178, 0, 0.1) 100%
   ),
   #fff;
 margin-left: auto;
 margin-right: auto;
 margin-top: 19px;
 display: flex;
 justify-content: flex-start;
 align-items: center;
}

.third-div-action-plan {
 width: 230px;
 height: 24px;
 margin-left: 32px;
 margin-top: 22px;
}

.second-span-action-plan {
 color: #2f4358;
 font-feature-settings: "clig" off, "liga" off;
 font-family: Founders Grotesk;
 font-size: 24px;
 font-style: normal;
 font-weight: 600;
 line-height: 24px;
}

.fourth-div-action-plan {
 width: 1450px;
 height: 215px;
 border-radius: 20px;
 border: 1.5px dashed #2f4358;
 background: linear-gradient(
     225deg,
     rgba(87, 239, 195, 0.1) 0%,
     rgba(193, 127, 242, 0.1) 51.26%,
     rgba(255, 178, 0, 0.1) 100%
   ),
   #fff;
 margin-left: auto;
 margin-right: auto;
 margin-top: 19px;
 display: flex;
 justify-content: center;
 align-items: center;
}

.coming-soon-div {
 width: 165px;
 height: 36px;
}

.fifth-action-plan-div {
 width: 240px;
 height: 24px;
 margin-left: 32px;
 margin-top: 22px;
}

.third-span-action-plan-div {
 color: #2f4358;
 font-feature-settings: "clig" off, "liga" off;
 font-family: Founders Grotesk;
 font-size: 24px;
 font-style: normal;
 font-weight: 600;
 line-height: 24px;
}

.sixth-div-action-plan {
 width: 1450px;
 height: 215px;
 border-radius: 20px;
 border: 1.5px dashed #2f4358;
 background: linear-gradient(
     225deg,
     rgba(87, 239, 195, 0.1) 0%,
     rgba(193, 127, 242, 0.1) 51.26%,
     rgba(255, 178, 0, 0.1) 100%
   ),
   #fff;
 margin-left: auto;
 margin-right: auto;
 margin-top: 19px;
 display: flex;
 justify-content: center;
 align-items: center;
}

.second-div-action-plan-main-div {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 margin-left: 42px;
}

.second-div-action-plan-main-div-first {
 width: 294px;
}

.second-div-action-plan-main-div-first-span {
 color: #2f4358;
 font-feature-settings: "clig" off, "liga" off;
 font-family: Founders Grotesk;
 font-size: 24px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
}

.second-div-action-plan-main-div-second {
 width: 280px;
 margin-left: -15px;
}

.second-div-action-plan-main-div-second-span {
 color: #2f4358;
 font-feature-settings: "clig" off, "liga" off;
 font-family: Founders Grotesk;
 font-size: 24px;
 font-style: normal;
 font-weight: 800;
 line-height: 24px;
}

.second-div-action-plan-img-div {
 width: 328.501px;
 height: 95.994px;
 margin-top: 30px;
}

.second-div-action-plan-image {
 stroke-width: 3px;
 stroke: #fcb50c;
 filter: drop-shadow(
   0px 5.999999523162842px 5.999999523162842px rgba(0, 0, 0, 0.05)
 );
}

.second-div-action-plan-last-div-main {
 width: 200px;
 height: 42px;
 border-radius: 30px;
 border: 1.5px solid #2f4358;
 background: linear-gradient(
     225deg,
     rgba(87, 239, 195, 0.5) 0%,
     rgba(193, 127, 242, 0.5) 51.26%,
     rgba(255, 178, 0, 0.5) 100%
   ),
   #fff;
 box-shadow: 0px 5px 20px 0px #ddc3f8;
 display: flex;
 justify-content: center;
 align-items: center;
 margin-left: 15.93px;
 cursor: pointer;
}

.second-div-action-plan-last-div-main-span {
 color: #2f4358;
 text-align: center;
 font-family: Founders Grotesk;
 font-size: 16px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
}


.profile-first-section{
 top: -900px;
 left: 170px;
 width: 500px;

}

.profile-first-section-main-div{
 display: flex;
 flex-direction: row;
 align-items: center;
 margin: 50px 0 0 30px;
}

.profile-image-div{
 width: 48px;
height: 48px;
flex-shrink: 0;
}

.profile-name-div{
 width: 438px;
 height: 28px;
 margin-left: 12px;
}

.profile-name-span{
 color: #2F4358;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Fraunces;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 100% */
}