.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    align-items: center;
    gap: 24px;
    background: var(--Background-Page-Base, #F5F3F6);
}

.blurBackground{
    background: rgba(21, 18, 23, 0.16);
}