.cardContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 344px;
    margin-top: 23px;
    background-color: #FFF;
    border-radius: 20px;
    flex-shrink: 0;
    margin-left: 16px
}

.imageContainer{
    width: 256px;
    height: 256px;
    margin-top: 52px;
    border-radius:2% ;
    overflow: hidden;
}

.userInfo{
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-bottom: 37px;
}

.userName{
    color: #2F4358;
    text-align: center;
    font-family: Fraunces;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px; /* 120.833% */
}

.deliveryInfo{
    color: rgba(47, 67, 88, 0.50);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Founders Grotesk";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 100% */
}

.generalInfo{
    display: flex;
    flex-direction: column;
    width: inherit;
    height: auto;
    padding: 0 32px 33px 32px;
}

.generalInfoHeading{
    margin: 3px 0;
    width: 100%;
    height: auto;
    padding: 1px;
    color: #2F4358;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Founders Grotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 100% */
}

.generalInfoContentInfo{
    color: #2F4358;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Founders Grotesk";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 100% */
}

.generalInfoContentHeading{
    color: rgba(47, 67, 88, 0.50);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
}

.generalInfoContent{
    display: flex;
    flex-direction: column;
    margin: 6px 0;
    width: 100%;
    height: auto;
}