.container{

}

.mainContent{
    display: flex;
    padding: 24px var(--Container, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.footerContainer{
    display: flex;
    align-items: center;
    align-self: stretch;

    background: var(--Muted-White, #FFF);
    box-shadow: 0px -1px 2px 0px rgba(33, 31, 38, 0.08);
}

.discardButton{
    display: flex;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);
    flex: 1 0 0;
    align-self: stretch;
    background: var(--Base-Components-Red-subtle, #FFF6F5);

    color: var(--Base-Text-red-midemp, #AE4B37);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    cursor: pointer;
}

.acceptButton{
    display: flex;
    padding: 13px var(--Container, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);
    flex: 1 0 0;

    cursor: pointer;

    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}