.container{
    width: 100%;
    height : 100%;
}

.profileTabOption{
    display: flex;
    width: 100%;
    padding: 24px;
    align-items: flex-start;
    gap: var(--Container, 16px);

    border-top: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.primaryLabelContainerClass{
    padding: 4px 0px !important;
}

.mainContentTypo{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.selectClassname{
    left: 0px;
    height: auto;
    z-index: 112;
}

.profileTabOptionText{
    display: flex;
    width: 400px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Tertiary, 2px);
    flex-shrink: 0;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;
}

.innerContainerClass{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-radius: var(--Container, 16px);
    background: var(--Surface-Container-primary, #FFF);
}

.infoTextContainer{
    display: flex;
    padding: var(--Container, 16px) 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    min-width: 566px;
    width: 100%;
    background-color: #ffffff;
    
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}