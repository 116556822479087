.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.field {
    display: flex;
    width: 424px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.errorPopContainer{
    position: absolute;
}

.errorPopup{
    display: flex;
    margin-bottom: 40px;
}

.fieldName {
    color: var(--Base-Text-grey-midemp, #66636D);

    /* Label/Label 2 */
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
}

.fieldValue {
    display: flex;
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;

    color: var(--Base-Text-grey-lowemp, #C0BAC7);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    display: flex;
    align-items: center;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

}

.passwordFieldValue:focus {
    outline-color: #F4E4FF;
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
    color: var(--Base-Text-grey-midemp, #66636D);
}

.email {
    background: var(--Base-Components-Grey-mid, #EAE7EC) !important;
}

.hidePasswordIcon {
    display: flex;
    padding: 13px;
    align-items: center;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

    border-left: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

}

.passwordField {
    display: flex;
    align-items: center;
    align-self: stretch;
}

.passwordFieldValue {

    display: flex;
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;

    color: var(--Base-Text-grey-lowemp, #C0BAC7);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    display: flex;
    align-items: center;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.notMatching {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    color: var(--Base-Text-red-midemp, #AE4B37);

    /* Label/Label 3 */
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;

    flex: 1 0 0;
}