.container{
    display: flex;
    padding: 8px 0px;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;

    border-radius: var(--Circle, 200px);
}

.noDataBar{
    display: flex;
    width: 100%;
    height: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;

    border-radius: 4px;
    border: 1px dashed var(--Base-Stroke-Grey-low, #DCD8E0);
    background: var(--Base-Components-Grey-subtle, #FAF9FB);
}