.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.emptyIcon{
    width: 160px;
    height: 160px;
}

.contentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainContentTypo{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.096px;
}

.secondaryTextTypo{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}