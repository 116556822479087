.container{
    width: 48px;
    height: 52px;
    padding: 13px;
    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBase-Primary-focused, #f0ebf3);
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-left: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: var(--Background-Component-onLayer-Rest, #FFF);
}

.container:active{
    border: 1px solid var(--Stroke-onBase-Primary-focused, #D0A9EF);
}