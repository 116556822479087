.container{
    display: flex;
    padding: 3px 12px;
    align-items: center;
    gap: 8px;

    cursor: pointer;
    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Components-Red-dark, #EE9887);
    background: var(--Base-Components-Red-subtle, #FFF6F5);
}

.leftContainer{

}

.rightContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: -2px;
}

.labelTypo{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.contentTypo{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.deviceDot{
    width: 8px;
    height: 8px;

    background-color: #EE9887;
    border-radius: 50%;

}