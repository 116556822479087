.container{
  display: flex;
  padding: 24px 24px var(--Primary, 8px) 24px;
  align-items: center;
  gap: var(--Secondary, 4px);
  align-self: stretch;
  position: sticky;
  top: 121px;
  z-index: 111;
  background-color: #EAE7EC;
}

.headingMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterContainer{
  display: flex;
  padding: 6px var(--Secondary, 4px);
  align-items: center;
  gap: var(--Secondary, 4px);
  align-self: stretch;

  border-radius: var(--Primary, 8px);
  border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
  background: var(--Base-Layers-layer-primary, #FFF);
}

.leftContainer{
  display: flex;
  align-items: center;
  gap: 16px;
}

.headingMainText {
  color: #000;
  text-align: right;

  /* Title/Title 2 */
  font-family: "Founders Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%; /* 25.6px */
  letter-spacing: 0.08px;
}

.progressMonitoringType {
  color: var(--Base-Text-grey-midemp, #66636d);
  text-align: right;

  /* Title/Title 3 */
  font-family: "Founders Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 128%; /* 25.6px */
  letter-spacing: 0.08px;
  margin-left: 5px;
  cursor: pointer;
}

.dropDownMain {
  position: relative;
  cursor: pointer;
}

.dropDownImage{
  cursor: pointer;
}

.dropDownOptionsMain {
  position: absolute;
  width: 196px;
  height: 86px;
  top: 25px;
  left: -125px;
  border-radius: var(--Tertiary, 2px);
  background: var(--Base-Layers-layer-primary, #fff);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.3s, opacity 0.3s ease;
}

.dropDownOptionsMainHidden {
  visibility: hidden;
  opacity: 0;
}

.optionText {
  color: var(--Base-Text-grey-midemp, #66636d);

  /* Body/Body 3 */
  font-family: "Founders Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.064px;
  cursor: pointer;
  margin-top: 5px;
}

.dropDownContainer{
  display: flex;
}