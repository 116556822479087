.heading {

    align-self: stretch;


    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    gap: var(--space-space-40, 12px);
    flex: 1 0 0;

    /* display: flex; */
    padding-bottom: var(--space-space-130, 48px);
    /* flex-direction: column; */


}

.headingStyle{
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Display/Display 3 */
    font-family: "Founders Grotesk Medium";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%;
    /* 46.4px */
    letter-spacing: 0.04px;
}

.buttonExport {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    /* Body/Body 2 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-mid, #D2CDD7);

}

.buttonAddNewPractice {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 2 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
    cursor: pointer;
}

.buttonAddNewPractice:hover {
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Hover, linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), #F5F3F6);
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.buttonGroup {
    left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--space-space-40, 12px);

    display: flex;
    padding-left: var(--space-space-70, 24px);
    justify-content: flex-end;
    align-items: flex-start;
}

.addNewPracticeFormStepOne{
    z-index: 101;
}

.formBackground{
    overflow:scroll;
    position: fixed;
    background: rgba(3, 3, 3, 0.4);
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-shrink: 0;
    display:flex;
    justify-content: center;
}