.container{
display: flex;
padding: 0px var(--Container, 16px) var(--Container, 16px) var(--Container, 16px);
align-items: center;
gap: var(--Container, 16px);
align-self: stretch;
}

.noDataImage{
  display: flex;
padding: 0px var(--Container, 16px) var(--Container, 16px) var(--Container, 16px);
align-items: center;
gap: var(--Container, 16px);
align-self: stretch;
}

.noDataText{
  flex: 1 0 0;
  color: var(--Base-Text-grey-midemp, #66636D);

/* Label/Label 2 */
font-family: "Founders Grotesk";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
letter-spacing: 0.084px;
}