.container{
    display: flex;
    flex-direction: column;
    padding: var(--Container, 16px) var(--Container, 16px) var(--Container, 16px) 24px;
    justify-content: space-between;
    align-self: stretch;

    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.betaIconContainer{
    display: flex;
    padding: var(--Tertiary, 2px) 12px;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Violet-dark, #C899EC);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);

    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.outerContainer{
    min-height: 20px;
    display: flex;
    width: 100%;
    padding: 0 12px;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-tertiary, #FAF9FB);
}

.primaryText{
    display: flex;
    gap: 6px;
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.secondaryText{
    display: flex;
    gap: 8px;
    
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.084px;
}