.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;
}

.createButton{
    width: 100%;
    padding: 13px 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border: 2px solid transparent;

    border-radius: var(--Primary, 8px);
    background: var(--Background-Component-onLayer-Disabled, #EBE8ED);

    color:  rgba(21, 18, 23, 0.48);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;

}

.enabledClassname{
    color: black;
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.individualPractionerButton{
    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.infoIcon{
    width: var(--Container, 16px);
    height: var(--Container, 16px);
    margin-left: 5px;
}