.container{
    display: flex;
    /* width: 62px; */
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);
    align-self: stretch;
    height: 100%;

    background: inherit
}

.container:hover{
    background-color: #FCF8FF;
}

.linkButton{
    display: flex;
    height: 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-bottom: 1px solid var(--Base-Stroke-Brand-mid, #DAB5F5);

    cursor: pointer;

    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.224px;
}

.hoverEffect{
    background-color: #FCF8FF;
}

.overlayButton{
    width: auto;
    padding: 8px;
    height: auto;
    cursor: pointer;
    background-color: #FFF;

    color: var(--Base-Text-red-midemp, #AE4B37);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    border-radius: var(--Secondary, 4px);
    background: var(--Base-Layers-layer-primary, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}

#button-tooltip > .tooltip-inner {
    border-radius: var(--Tertiary, 2px);
    background: var(--Base-Layers-layer-primary, #FFF);
}


.icon{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}