.container{
    display: flex;
    padding: 0px 32px 32px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.headingTypography{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%; /* 29.76px */
    letter-spacing: 0.096px;
}

.buttonContainer{
    display: flex;
    align-items: flex-start;
    gap: var(--Container, 16px);
}

.headingContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--Primary, 8px);
    align-self: stretch;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.buttonIcon{
    width: 22px;
    height: 22px;
}

.buttonsContainer{
    height: 48px;
    width: 152px;
}

@media screen and (max-width: 680px) {
    .buttonContainer{
        width: 100%;
        flex-direction: column;
    }
    .buttonsContainer{
        width: 100%;
    }
}