.container{
    display: flex;
    width: 504px;
    align-items: flex-start;

    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Background-Layer-Base-Primary-Rest, #FFF);

    display: flex;
    padding: var(--Primary, 8px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    flex: 1 0 0;
}

@media screen and (max-width: 680px) {
    .container{
        width: 100%;
    }
}