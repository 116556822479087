.log-chart-row-single-tab {
    margin: 0;
    width: 64px;
    height: 53px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
}


.bar-zero-zero{
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewMoreDiv {
    width: 60px;
    height: 24px;
    border-radius: 50px;
    border: 1px solid #2f4358;
    background: rgba(253, 153, 151, 0.3);
    gap: 5px;
    display: flex;
}
  
.view-more-div-span {
    color: #3f3862;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Founders Grotesk;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    margin-left: 8px;
}
  
.view-more-div-image {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}
  
.bar-percentage-div {
    width: 48px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.bar-percentage-span {
    color: #3f3862;  
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Founders Grotesk;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
    margin-left: 10px;
    margin-top: 10px;
}

.greenText{
    background-color: #20D29F;
}

.redText{
    background-color: rgba(253, 153, 151, 0.30);
}

.yellowText{
    background-color: #FFB200;
}