.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.secondaryHeader{
    display: flex;
    padding: 12px var(--Container, 16px);
    align-items: center;
    gap: 24px;
    align-self: stretch;
    width: 100%;

    background: var(--Base-Layers-layer-primary, #FFF);
}

.scrollArrows{
    width: 24px;
    height: 24px;
}

.secondaryHeaderContent{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.tableContainer{
    display: flex;
    flex-direction: column;
}

.titleClass{
    display: flex;
    padding: 0 8px;
    gap: var(--Tertiary, 2px);
    flex: 1 0 0;
    width: 402px;
    max-width: 402px;
    flex: none;
    width: 100%;
    justify-content: flex-start !important;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}

.tableClass{
    min-width: 10px;
    flex-basis: inherit;
}

.columnContainer{
    width: 106px;
}

.columnContainer:hover{
    background: var(--Base-Components-Grey-subtle, #FAF9FB) !important;
}

.tableRowEffect{
    border-bottom: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
}

.rowColumnHeader{
    border-top: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    border-bottom: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
}
