.pageHeaderContext {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding-bottom: var(--space-space-50, 16px);
    gap: var(--space-space-130, 48px);
}

.subHeading {
    display: flex;
    align-items: center;
    gap: 4px;

    color: var(--Base-Text-grey-midemp, #66636D);
    cursor: pointer;

    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%;
    letter-spacing: 0.072px;

}

.subHeadingPractice{
    color: var(--Base-Text-brand-primary, #854DC4);

/* Caption/Caption 3 */
font-family: "Founders Grotesk";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 148%; /* 17.76px */
letter-spacing: 0.072px;
}

