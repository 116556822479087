.container{
    display: flex;
    padding: 12px var(--Container, 16px);
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;
    justify-content: space-between;

    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.leftContainer{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.rightContainer{
    display: flex;
    gap: 12px;
}

.leftContainerTypography{
    overflow: hidden;
    color: var(--Base-Text-grey-highemp, #211F26);
    text-overflow: ellipsis;
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.iconDimensions{
    width: 20px;
    height: 20px;

    cursor: pointer;
}

.closed{
    transform: rotate(180deg);
}