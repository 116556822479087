.container{
    display: flex;
    align-items: center;
    gap: var(--Secondary, 4px);
    align-self: stretch;
}

.container:hover{
    border-radius: var(--Primary, 8px);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.primaryContainer{
    display: flex;
    width: auto;
}

.optionContainer{
    position: absolute;
    height: auto;
    border-radius: var(--Tertiary, 2px);
    background: var(--Base-Layers-layer-primary, #fff);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 8px;
    z-index: 20;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s, opacity 0.3s ease;
}
  
.dropDownOptionsMainHidden {
    visibility: hidden;
    opacity: 0;
}  