.date-first-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
}
  
.date-first-div-text {
    margin-top: 10%;
    color: #2f4358;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Founders Grotesk;
    font-size: 8px;
    font-style: normal;
    font-weight: 100;
}

.month-first-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 12px;
}

.month-first-div-text {
    margin-top: 25%;
    color: #2f4358;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Founders Grotesk;
    font-size: 10px;
    font-style: normal;
    font-weight: bolder;
}

.border-month-start {
    border-radius: 10px 10px 10px 10px;
    border-top: 0.5px solid #b9e4d8;
    border-right: 0.5px solid #bbeadc;
    border-bottom: 0.5px solid #bcd6cf;
    background: linear-gradient(
      225deg,
      rgba(87, 239, 195, 0.1) 0%,
      rgba(193, 127, 242, 0.1) 51.26%,
      rgba(255, 178, 0, 0.1) 100%
    );
    opacity: 0.8;
}

.day-first-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 16px;
}
  
.day-first-div-text {
    font-size: 8px;
    margin-top: 20%;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Founders Grotesk;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
}

.week-text {
    color: #2f4358;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Founders Grotesk;
    font-size: 12px;
    font-style: normal;
    font-weight: bolder;
}
