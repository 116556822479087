.container{
  display: flex;
  padding: 24px 24px var(--Primary, 8px) 24px;
  align-items: center;
  gap: var(--Secondary, 4px);
  align-self: stretch;
}

.headingMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headingMainText {
  color: #000;
  text-align: right;

  /* Title/Title 2 */
  font-family: "Founders Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%; /* 25.6px */
  letter-spacing: 0.08px;
}

.progressMonitoringType {
  color: var(--Base-Text-grey-midemp, #66636d);
  text-align: right;

  /* Title/Title 3 */
  font-family: "Founders Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 128%; /* 25.6px */
  letter-spacing: 0.08px;
  margin-left: 5px;
  cursor: pointer;
}

.dropDownMain {
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropDownImage{
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.dropDownOptionsMain {
  position: absolute;
  width: 126px;
  min-height: 86px;
  height: auto;
  margin-top: 20px;
  border-radius: var(--Tertiary, 2px);
  background: var(--Base-Layers-layer-primary, #fff);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.3s, opacity 0.3s ease;
}

.dropDownOptionsMainHidden {
  visibility: hidden;
  opacity: 0;
}

.optionText {
  color: var(--Base-Text-grey-midemp, #66636d);
  font-family: "Founders Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.064px;
  cursor: pointer;
  margin-top: 5px;
}

.dropDownContainer{
  width: 100%;
  display: flex;
}