.team_info {
    height: fit-content;
    width: 344px;
    background-color: #fff;
    border-radius: 10px;
    margin-left: 16px;
    margin-top: 16px;
}


.care_team_heading {
    color: #2f4358;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Founders Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 32px;
    margin-left: 34px;
}
  
.images {
    display: flex;
    flex-direction: row;
    margin-left: 32px;
    margin-top: 16px;
}
  
.profile_image_two {
    margin-left: -20px;
    border-radius: 80px;
    border: 1.6px solid white;
    height: 44px;
    width: 44px;
}
  
.profile_image_one {
    border-radius: 80px;
    border: 1.6px solid white;
    height: 44px;
    width: 44px;
}
  
.invite {
    height: 44px;
    width: fit-content;
    margin-left: 18px;
}
  
.view-all {
    margin-left: 32px;
    margin-top: 16px;
    margin-bottom: 28.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
  
.view-all-text {
    color: rgba(47, 67, 88, 0.5);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Founders Grotesk;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-right: 11.25px;
}
  