.container{
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-left: 32px;
}

.items{
    display: flex;
    align-self: stretch;
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.inviteButtonContainer{
    width: 95px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(240,150,189);
    background: radial-gradient(circle, rgba(240,150,189,1) 0%, rgba(148,197,233,0.9640231092436975) 97%);    /* gap: 8px; */
    border-radius: 8px;
    gap: 8px;
}

.inviteButton{
    border: 2px solid #F5F3F6;
    padding: 4px 16px 6px 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    background-color: #FFF;
    padding: auto;
    width: 88px;
    height: 40px;
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}
