.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    width: 100%;

    overflow: auto;
}

.cardContainer{
    width: 100%;
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;
}

.cardContainer::-webkit-scrollbar {
    width: 10px; /* Width of the vertical scrollbar */
    height: 10px; /* Height of the horizontal scrollbar */
}

.cardContainer::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 5px; /* Rounded corners */
}

.cardContainer::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Thumb color on hover */
}

.cardContainer::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track background */
}

.followUpContainer{
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    padding: 8px 16px;

    border-radius: var(--Tertiary, 2px);
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.mainData{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.calenderIcon{
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.noContent{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.emptyIcon{
    width: 120px;
    height: 120px;
    flex-shrink: 0;
}
