.container{
    display: flex;
    padding: 14px 16px;
    gap: 2px;

    height: 72px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.leftContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.rightContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);
    cursor: pointer;
}

.mainContentTypo{
    color: var(--Base-Text-grey-highemp, #211F26);
    text-align: right;
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.secondaryMainContent{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.highlightedSecondaryMainContent{
    color: var(--Base-Text-brand-primary, #854DC4);
}

.penMarkerIcon{
    width: 16px;
    height: 16px;
}