.container{
    width: 100%;
    display: flex;
    align-items: center;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
}

.emptyPlaceHolderTypography{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.textArea{
    width: 100%;
    min-height: 144px;
    display: flex;
    align-self: stretch;
    padding: 13px var(--Container, 16px);
    /* overflow: scroll; */

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);

    vertical-align: text-top;
    resize: vertical;
}

.textArea:focus{
    border: 1px solid black;
}