.outerContainer {
    display: flex;
    padding: var(--Container, 16px) 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    /* background-color: #F1EFF3; */
    align-self: stretch;
    height: calc(100vh - 200px)
}

.innerContainer {
    display: flex;
    padding: 40px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    /* border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC); */
    /* background: var(--Base-Layers-layer-primary, #FFF); */
}

.icon {
    width: 160px;
    height: 160px;
}

.primaryText {
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%;
    /* 25.6px */
    letter-spacing: 0.08px;
}

.secondaryText {
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
}

.tableContainer{
    height: auto;
    overflow: hidden;
    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
}

.buttonAddNewPractice {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 2 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    cursor: pointer;
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);

}

.buttonAddNewPractice:hover {
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Hover, linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), #F5F3F6);
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.addNewPracticeFormStepOne{
    z-index: 101;
}

.formBackground{
    overflow:scroll;
    position: fixed;
    background: rgba(3, 3, 3, 0.4);
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-shrink: 0;
    display:flex;
    justify-content: center;
}