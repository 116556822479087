.tabOptions {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

    margin-bottom: var(--space-space-60, 20px);
    /* flex-direction: column; */

}

.tab {
    display: flex;
    min-width: var(--size-square-size-square-130, 48px);
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: var(--Base-Text-grey-midemp, #66636D);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
    border-bottom: 2px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

}

.tabActive {
    display: flex;
    min-width: var(--size-square-size-square-130, 48px);
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
    color: var(--Base-Text-brand-primary, #854DC4);
    border-bottom: var(--Tertiary, 2px) solid var(--Base-Stroke-Brand-mid, #DAB5F5);
}

.container{
    width: 100%;
}