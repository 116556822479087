.inputIcon{
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconContainer{
    display: flex;
    height: 100%;
    width: 54px;
    justify-content: center;
    align-items: center;

    border: 1px solid transparent;
}

.multiSelectContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.innerInputContainer{
    display: flex;
    min-height: 48px;
    height: auto;
    display: flex;
    padding: 13px var(--Container, 16px);
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    flex: 1 0 0;
}

.inputContainer{
    min-width: 100%;
    min-height: 48px;
    height: auto;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1 0 0;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: inherit;
}

.noninput{
    border: 1px solid transparent;
    width: 20px;
}

.disabledState{
    pointer-events: none;
    cursor: not-allowed;
    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);

    background: var(--Base-Components-Grey-low, #F1EFF3);
}

.input{
    background-color: inherit;
    border: 1px solid transparent;
    text-overflow: initial;
    width: auto;
}

.input:focus{
    border: 1px solid transparent;
    outline: none;
}

.selectField{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.dropdownOptions{
    width: 422px;
    min-height: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: absolute;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: var(--Background-Component-onLayer-Rest, #FFF);
}

.dropdownSingleOption{
    width: 100%;
    height: auto;
    display: flex;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    cursor: pointer;

    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);

    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.dropdownSingleOption:hover{
    background-color: #FCF8FF;
    font-weight: 600;
}

.selectedOption{
    display: flex;
    padding: 2px 4px 2px 8px;
    align-items: center;
    gap: 4px;
    min-height: 24px;
    min-width: 90px;
    width: auto;
    height: auto;

    border-radius: var(--Secondary, 4px);
    background: var(--Background-Component-Pearl-Aqua-Light-Layer-Rest, #CAF6E6);

    justify-content: space-between;
}

.cancelIcon{
    width: 12px;
    height: 12px;
}

.cancelIcon:hover{
    cursor: pointer;
}