.fieldContainer{
    display: flex;
    width: 424px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.formHeaderContainer{
    display: flex;
    flex-direction: column;
}

.cardFormBody{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
}

.primaryHeader{
    color:  #1A171C;

    font-family: "Founders Grotesk";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%; 
    letter-spacing: 0.04px;
}

.secondaryHeader{
    color:  #524B58;

    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
    letter-spacing: 0.064px;
}

.label{
    display: flex;
    padding: 0px  8px;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    width: 100%;

    color:  #7B7382;
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; 
    letter-spacing: 0.084px;
}

.inputField{
    display: flex;
    align-items: center;
    align-self: stretch;

    width: 100%;
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);

    border-radius:  8px;
    background:  #FFF;

    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;
}

.inputField:focus{
    display: flex;
    align-items: center;
    align-self: stretch;

    width: 100%;
    border: 1px solid  #D0A9EF;

    outline: none;

    border-radius:  8px;
    background:  #FFF;

    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;
}

.inputFieldContainer{
    display: flex;
    width: 100%;
    gap: 0;
}

.footerContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Primary, 8px);    
    align-self: stretch;
}

.footerButton{
    width: 100%;
    padding: 13px 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border: 2px solid transparent;

    border-radius: var(--Primary, 8px);
    background: var(--Background-Component-onLayer-Disabled, #EBE8ED);

    color:  rgba(21, 18, 23, 0.48);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;
}

.inputErrorContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.errorInput{
    border: 1px solid #9A3232;
}