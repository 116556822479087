.tableFields{
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
  background: var(--Base-Components-Grey-subtle, #FAF9FB);
}

.dateContainer{
display: flex;
width: 104px;
padding: 12px var(--Container, 16px);
align-items: center;
gap: var(--Primary, 8px);
align-self: stretch;
}

.dateContent{
  width: 104px;
  display: flex;
  align-items: center;
  gap : 4px;
  color: var(--Base-Text-grey-midemp, #66636D);
font-family: "Founders Grotesk";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
letter-spacing: 0.084px;
text-transform: uppercase;
}

.mealContainer{
  display: flex;
width: 334px;
padding: 12px;
align-items: center;
gap: var(--Primary, 8px);
align-self: stretch;
}

.mealContent{
  color: var(--Base-Text-grey-midemp, #66636D);
font-family: "Founders Grotesk";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
letter-spacing: 0.084px;
text-transform: uppercase;
}

.statusContainer{
display: flex;
padding: 12px 10px;
align-items: center;
gap: var(--Primary, 8px);
flex: 1 0 0;
align-self: stretch;
}

.statusContent{
  color: var(--Base-Text-grey-midemp, #66636D);
font-family: "Founders Grotesk";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
letter-spacing: 0.084px;
text-transform: uppercase;
}

.logIcon{
  width: var(--Container, 16px);
  height: var(--Container, 16px);
  flex-shrink: 0;
}

.plateScore{
  display: flex;
  justify-content: center;
  padding: 12px 20px;
  align-items: center;
  gap: var(--Primary, 8px);
  flex: 1 0 0;
  align-self: stretch;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--Base-Text-grey-midemp, #66636D);
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
  text-transform: uppercase;

}

.microNutrientsContainer{
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: var(--Primary, 8px);
  flex: 1 0 0;
  align-self: stretch;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.microNutrientsContent{
  color: var(--Base-Text-grey-midemp, #66636D);
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
  text-transform: uppercase;
}