.header{
    width: 100%;
    display: flex;
    padding: 16px 120px;
    justify-content: space-between;
    align-items: center;
    background-color: inherit;
}

.image{
    width: 21.6px;
    height: 24px;
}

.leftContainer{
    display: flex;
    height: auto;
    width: auto;
    align-items: center;
    gap: 8px;
}

.textlogo{
    width: 84px;
    height: 15.474px;
}

.rightContainer{
    display: flex;
    align-items: center;
    gap: 12px;
}

.rightContainerText{
    color:  #524B58;
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.rightButton{
    display: flex;
    padding: 5px 20.5px;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    border: 2px solid #F5F3F6;
    color: #1A171C;
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;
    width: 85px;
}

.rightContainerButton{
    display: flex;
    min-width: 95px;
    width: auto;
    height: 48px;
    justify-content: center;
    align-items: center;
    background: rgb(240,150,189);
    background: radial-gradient(circle, rgba(240,150,189,1) 0%, rgba(148,197,233,0.9640231092436975) 97%);    /* gap: 8px; */
    border-radius: 8px;
    border: 2px solid #F5F3F6;
}

.termsAndConditionsContainer{
    display: ruby;
    padding: var(--Container, 16px) 40px;
    justify-content: center;
    align-items: center;
    width: 504px;
    height: auto;
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);

    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.hyperLinks{
    color: var(--Foreground-Text-onBase-Accent-rest, #8427CE);
    display: flex;
}

.linkText{
    cursor: pointer;
    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.064px;
}