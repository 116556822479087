.container{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.064px;
}

.iconPaint{
    width: var(--Container, 16px);
    height: var(--Container, 16px);
}

.container:hover{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.activeClass{
    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    border-radius: var(--Secondary, 4px) var(--Secondary, 4px) 0px 0px;
    border-top: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
    border-right: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
    border-left: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
    border-bottom: 1px solid #FAF9FB;
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.activeClass:hover{
    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}