.practiceListContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.tableHeader {
    display: flex;
    align-items: center;
    align-self: stretch;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-tertiary, #FAF9FB);

    border-top-left-radius: var(--Container, 16px);
    border-top-right-radius: var(--Container, 16px);

}

.tableColumn {
    display: flex;
    height: 44px;
    padding: var(--space-space-40, 12px) 16px;
    align-items: center;
    gap: 2px;
    flex: 1 0 0;

    background: var(--Base-Components-Grey-subtle, #FAF9FB);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;

    border-radius: var(--Container, 16px);

}

.tableColumnAction {
    display: flex;
    padding: var(--space-space-40, 12px) 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 2px;
    flex: 1 0 0;
    align-self: stretch;

    background: var(--Base-Components-Grey-subtle, #FAF9FB);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;

    border-radius: var(--Container, 16px);
}

.tableColumnData {

    display: flex;
    align-items: center;
    align-self: stretch;

    display: flex;
    align-items: flex-start;
    display: flex;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;

    background: var(--Base-Layers-layer-primary, #FFF);

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.tableColumnDataContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    display: flex;
    padding: 16px var(--space-space-40, 12px);
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
}

.avatar {
    display: flex;
    width: var(--size-square-size-square-130, 48px);
    height: var(--size-square-size-square-130, 48px);
    padding: 13px 0px;
    justify-content: center;
    align-items: center;

    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.avatarData {
    width: var(--size-square-size-square-130, 48px);
    flex-shrink: 0;

    color: var(--Base-Text-grey-highemp, #211F26);
    text-align: center;

    /* Body/Body 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
}

.practiceName {
    color: var(--Base-Text-grey-midemp, #66636D);

    /* Body/Body 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
}

.practiceUrl {
    color: var(--Base-Text-grey-midemp, #66636D);

    /* Label/Label 3 */
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
}

.nameAndUrlContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

}

.resendInvite {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;

}

.resendInviteText {
    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;

    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);
}

.tableColumnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
}

.roleStyle{
    display: flex;
padding: 6px 10px;
justify-content: center;
align-items: center;
gap: 4px;

border-radius: var(--Circle, 200px);
background: var(--Base-Components-Grey-low, #F1EFF3);

color: var(--Base-Text-grey-midemp, #66636D);

/* Label/Label 3 */
font-family: "Founders Grotesk";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
letter-spacing: 0.084px;
}

.statusStyle{
    display: flex;
padding: 6px 10px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: var(--Circle, 200px);
background: var(--Base-Components-Green-low, #E5FAF2);

color: var(--Base-Text-green-midemp, #2A8466);

/* Label/Label 3 */
font-family: "Founders Grotesk";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
letter-spacing: 0.084px;
}

.patientInfoStyle{
    color: var(--Base-Text-grey-midemp, #66636D);

/* Body/Body 3 */
font-family: "Founders Grotesk";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
letter-spacing: 0.064px;
}

.paginationContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 4px;
    margin-top: 16px;
}