.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Primary, 8px);
    align-self: stretch;
}

.checkBoxContainer{
    display: flex;
    align-items: flex-start;
    gap: var(--Primary, 8px);
    align-self: stretch;
    align-items: center;
}

.placeHolderTypography{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}