.horizontalDivider{
    width: 100%;
    border-bottom: 1px solid var(--Stroke-onBase-Subtle, #F5F3F6);
}

.mainSpinner{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #F5F3F6;
    justify-content: center;
    align-items: center;
    position: absolute;
}
