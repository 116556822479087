.container{
    display: flex;
    width: 400px;
    padding: var(--Container, 16px);
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
}

.textContainer{
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.cancelIcon{
    cursor: pointer;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}