.container{
    display: flex;
    width: 504px;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;

    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.logoOuterContainer{
    display: flex;
    align-items: center;
    gap: var(--Primary, 8px);
}

.imgLogo{
    width: 21.6px;
    height: 24px;
}

.textLogo{
    width: 84px;
    height: 15.474px;
}

@media screen and (max-width: 680px) {
    .container{
        width: 100%;
    }
}