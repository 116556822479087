.container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cameraContainer{
    padding: 20px;
    position: absolute;
    width: auto;
    height: auto;
    z-index: 100;
    display: flex;
    flex-direction: column;
}

.cancelIcon{
    width: 12px;
    height: 12px;
    margin-top: 85px;
    margin-left: 578px;
    position: absolute;
    background-color: aliceblue;
}

.cancelIcon:hover{
    cursor: pointer;
}

.captureButton{
    width: 100px;
    height: 30px;
    border: 1px solid transparent;
    background-color: aliceblue;
    position: absolute;
    margin-top: 530px;
    margin-left: 218px;
    border-radius: 10px;
}

.closeButton{
    width: 100px;
    height: 30px;
    border: 1px solid transparent;
    background-color: aliceblue;
    position: absolute;
    margin-top: 530px;
    margin-left: 348px;
    border-radius: 10px;
}