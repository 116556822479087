.container{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.noDataContainer{
    display: flex;
    flex-direction: column;
}

.rightTextButton:hover{
    color: var(--Base-Text-brand-highemp, #33265F) !important;
    border-bottom: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
}

.billingContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.leftHeaderContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Tertiary, 2px);
}

.billingHeader{
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

    padding: 14px var(--Container, 16px);
}

.rightContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--Secondary, 4px);
    min-width: 116px;
}

.rightTextButton{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Secondary, 4px);

    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);

    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    cursor: pointer;
}

.nextBillingContainer{
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: var(--Tertiary, 2px);
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.nextBillingDate{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.nextBillingTextContainer{
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.nonActive{

    border-bottom: none;
    color: var(--Base-Text-brand-midemp, #d3cbe2);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    cursor: not-allowed;

}

.nonActive:hover{
    color: var(--Base-Text-brand-midemp, #d3cbe2) !important;
    border-bottom: none;
}

.emptyIcon{
    width: 120px;
    height: 120px;
    flex-shrink: 0;
}

.noContent{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.billingBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.headerSecondaryTextTypo{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.headerPrimaryTextTypo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);
}

.headerPrimaryLabel{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.betaTagContainer{
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Secondary, 4px);
    background: var(--Base-Components-Green-low, #E5FAF2);

    color: var(--Base-Text-green-midemp, #2A8466);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.calenderIcon{
    width: 16px;
    height: 16px;
}