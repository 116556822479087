.container{
    display: flex;
    width: 560px;
    flex-direction: column;
    align-items: center;
    max-height: 530px;

    position: fixed;
    bottom: 0;
    left: 82px;
    overflow: hidden;
    z-index: 119;

    border-radius: var(--Primary, 8px) var(--Primary, 8px) 0px 0px;
    background: var(--Base-Layers-layer-primary, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}

.footerContainerClass{
    display: flex;
    padding: 13px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: auto;
    align-self: stretch;

}

.buttonClassname{
    display: flex;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    max-width: 135px !important;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.buttonClassname:hover{
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Hover, linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), #F5F3F6);
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.enabledClassname{
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    max-width: 510px;
}

.formFieldContainer{
    width: 100%;
}

.dropdownContainer{
    height: 190px;
    overflow-y: scroll;
}

.mainDropdown{
    position:  relative !important;    
}

.expressNoteContainer{
    padding-top: 16px;
}

.formBodyClassname{
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.inputContainerClass{
    width: 100%;
}

.expressNoteLabel{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}