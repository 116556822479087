.container{
    display: flex;
    height: 40px;
    padding: 6px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: auto;
    cursor: pointer;

    border-radius: var(--Primary, 8px);
    /* background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6); */
    background: #faf9fb;
    color: #66636d;
}

.container:hover{
    border-radius: var(--Primary, 8px);
    /* background: var(--Interactive-Hover, linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), #F5F3F6); */
    background: #faf9fb;
    /* box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48)); */
}