.container{
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    cursor: context-menu;
}

.statusIcon{
    width: 16px;
    height: 16px;
}

.dateDetailsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-secondary, #FDFCFD);
}

.deviceDetailsContainer{
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.deviceContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
}

.viewHistoryButtton{
    display: flex;
    height: 48px;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 2px solid var(--Interactive-Rest, #F5F3F6);
    border-radius: var(--Primary, 8px);
    background-color: #fff;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.viewHistoryButttonContainer{
    border-radius: var(--Primary, 8px);
    width: 100%;
    cursor: pointer;
    padding: 2px;
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.viewHistoryButttonContainer:hover{
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.cgmPhotoContainer{
    display: flex;
    width: 72px;
    height: 72px;
    padding: 8px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.tertiaryContentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 2px;
}

.cgmIcon{
    width: 56px;
    height: 56px;
    flex-shrink: 0;
}

.rightUpperContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.tertiaryContentMainTypo{
    align-self: stretch;
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 20px;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.textAlignRight{
    text-align: right;
}

.tertiaryContentTypo{
    align-self: stretch;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}