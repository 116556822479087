.container{
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;

    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Surface-Container-primary, #FFF);

    display: flex;
    flex-direction: column;
}

.rightContainer{
    display: flex;
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;
}

.tabOptionContainer{
    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

    display: flex;
    align-items: center;

    cursor: pointer;
}

.primaryAbnormalitiesContainer{
    display: flex;
    padding: var(--Container, 16px) 12px;
    align-items: center;
    gap: var(--Primary, 8px);
    flex: 1 0 0;
    padding-left: 29px;
    
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-tertiary, #FAF9FB);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.abnormalitiesTextContainer{
    padding-left: 2px;
}

.primaryTextAbnormalContainer{
    /* width: 251px; */
}

.anormalitiesContainer{
    display: flex;
    align-items: flex-start;
    align-self: stretch;
}

.tabOptionsInnerContainer{
    display: flex;
    padding: 7px 12px;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.tabOptionsInnerContainer:hover{
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.tabOptionsInnerLeft{
    border-radius: var(--Circle, 200px) 0px 0px var(--Circle, 200px);
    border-right: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.tabOptionsInnerCenter{
    border-right: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.activeTabOption{
    background: var(--Base-Components-Grey-subtle, #FAF9FB);
    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.activeTabOption:hover{
    color: #33265F;
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.tabOptionsInnerRight{
    border-radius: 0px var(--Circle, 200px) var(--Circle, 200px) 0px;
    border-right: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.topContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    padding: 14px 16px;
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.topLeftContainer{
    display: flex;
    flex-direction: column;
}

.tableColumnContainer{
    display: flex;
    align-items: center;
    gap: var(--Tertiary, 2px);
    flex: 1;
    align-self: stretch;
    padding: 0;
    flex-wrap: wrap;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.timeDateContainer{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.primaryText{
    display: flex;
    align-items: center;
    gap : 6px;

    color: var(--Base-Text-grey-highemp, #211F26);
    text-align: right;
    font-family: "Founders Grotesk SemiBold";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.secondaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.innerClassName{
    width: 100%;
}

.columnRow{
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    padding: 12px var(--Container, 16px);
    background: var(--Base-Components-Grey-subtle, #FAF9FB);
    
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}

.tableRow{
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.dateColumnContainer{
    display: flex;
    gap: 2px;
}

.betaIconContainer{
    display: flex;
    padding: var(--Tertiary, 2px) 12px;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Violet-dark, #C899EC);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);

    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.dateSortIcon{
    width: var(--Container, 16px);
    height: var(--Container, 16px);
}

.footerContainer{
    display: flex;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.footerLeftContainer{
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.tags{
    display: flex;
    padding: var(--Secondary, 4px) var(--Primary, 8px);
    align-items: center;
    gap: var(--Primary, 8px);
    border-radius: var(--Secondary, 4px);
}

.tagIcon{
    width: var(--Container, 16px);
    height: var(--Container, 16px);
}

.tagLabel{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    padding-top: 14px;

}

.copyDataOuterContainer{
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    background : linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), var(--Muted-Old-950, #F5F3F6);;

    border-radius: var(--Circle, 200px);
    border: var(--Tertiary, 2px) solid var(--Interactive-Rest, #F5F3F6);

    cursor: pointer;
}

.copyDataOuterContainer:hover{
    border: 2px solid var(--Interactive-Hover, #F5F3F6);
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.copyDataInnerContainer{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    padding: 4px var(--Container, 14px);
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    background-color: #fff;
    border-radius: var(--Circle, 200px);
}

.footerTagContainer{
    display: flex;
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;
}


.exclamationIcon{
    margin-top: 3px;
    width: 16px;
    height: 16px;
}

.lunchPPBlock{
    width: 100%;
    padding-left: 7px;
}

.dinnerPPBlock{
    width: 100%;
    padding-left: 2px;
}

.betaTagContainer{
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Secondary, 4px);
    background: var(--Base-Components-Green-low, #E5FAF2);

    color: var(--Base-Text-green-midemp, #2A8466);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}
