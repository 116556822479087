.container{
    width: auto;
    height: auto;
    padding: 6px;
    position: absolute;

    font-size: 14px;
    margin-bottom: 69px;
    border-radius: 8px;

    border: 1px solid rgb(136, 134, 134);
    background-color: #fff;
}