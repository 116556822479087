.container{
    display: flex;
    padding: var(--Secondary, 4px) var(--Primary, 8px);
    align-items: center;
    gap: 6px;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.strip{
    width: 20px;
    height: 2px;
    display: flex;
    align-items: center;
    gap: var(--Primary, 8px);
}

.inRange{
    stroke-width: 2px;
    background-color: var(--Base-Components-Green-dark, #7ACCAE);
}

.belowRange{
    stroke-width: 2px;
    background-color: var(--Base-Components-Red-dark, #EE9887);
}

.aboveRange{
    stroke-width: 2px;
    background-color: var(--Base-Components-Yellow-dark, #E4A944);
}

.valueTypo{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}