.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
    height: auto;
    width: 560px;
    padding: 0px var(--Container, 16px);
    align-items: flex-start;
    gap: var(--Primary, 8px);
}

.primaryHeader{
    align-self: stretch;

    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk";
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 70.4px */
}

.secondaryHeader{
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}