.container{
    width: 100%;
    height: 100%;
    position: absolute;
    padding-top: 30px;
    padding-left: 450px;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: default;
}

.modalContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    width: 600px;
    height: 617px;
}

.linkContent{
    color: var(--Foreground-Text-onBase-Accent-rest, #8427CE);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;
    text-decoration-line: underline;
    cursor: pointer;
}