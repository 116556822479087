.container{
    width: 100%;
    height: 100%;
    display: grid;
    background-color: rgba(230, 225, 225, 0.932);
    overflow-x: hidden;
    grid-template-columns: 220px auto;
}

.outerClass{
    display: inline-flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    flex-shrink: 0;

    border-right: 1px solid var(--Stroke-onBG-Subtle, #E1DDE3);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.outerClassText{
    display: flex;
    width: 240px;
    padding: 13px var(--Container, 16px);
    align-items: center;
    gap: var(--Primary, 8px);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.primaryInnerClass{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.primaryInnerClassText{
    display: flex;
    width: 240px;
    padding: var(--Secondary, 4px) var(--Container, 16px);
    align-items: center;
    gap: var(--Primary, 8px);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.secondaryClassName{
    display: flex;
    width: 240px;
    padding: 13px var(--Container, 16px);
    align-items: center;
    gap: var(--Primary, 8px);
    cursor: pointer;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.activeTabOption{
    color: var(--Base-Text-brand-primary, #854DC4);
    font-weight: 500;
}

.profileTabOption{
    display: flex;
    width: 100%;
    padding: 24px;
    align-items: flex-start;
    gap: var(--Container, 16px);
    cursor: pointer;

    border-top: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.profileTabOptionText{
    display: flex;
    width: 400px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Tertiary, 2px);
    flex-shrink: 0;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;
}

.innerContainerClass{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    border-radius: var(--Container, 16px);
    background: var(--Surface-Container-primary, #FFF);
}

.infoTextContainer{
    display: flex;
    padding: var(--Container, 16px) 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    min-width: 566px;
    width: 100%;
    background-color: #ffffff;
    gap: 24px;

    border-top: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.enabledClassname{
    color: black;
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.formContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;
    width: 100%;
    background-color: white;
    background: var(--Background-Layer-Base-Primary-Rest, #FFF);
}

.inputFieldRow{
    display: flex;
    flex-direction: row;
}

.errorClassName{
    display: flex;
    position: absolute;
    margin-left: 440px ;
    margin-bottom: 100px;
}

.commonFieldContainer{
    width: 100%;
}

.passwordInputField{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* border-right: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED); */
}

.componentContainer{
    width: 100%;
}

.selectClass{
    width: 100% !important;
}

.customBasicDropdown{
    min-width: 100%;
}

.innerPrimaryContainer{
    min-width: 100%;
}

.selectTimeZoneClass{
    min-width: 100%;
    left: 0px;
    height: auto;
}

.spinnerClass{
    top:0;
    left:0;
}