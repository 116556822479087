.container{
    display: flex;
    padding: var(--Container, 16px);
    align-items: center;
    gap: var(--Container, 16px);
    flex: 1 0 0;
    align-self: stretch;

    background: var(--Base-Layers-layer-primary, #FFF);
}

.iconContainer{
    width: 108px;
    height: 108px;
}

.textContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Secondary, 4px);
    flex: 1 0 0;
}

.primaryText{
    align-self: stretch;
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.secondaryText{
    align-self: stretch;
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}