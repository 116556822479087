.bodyContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
}

.tabContainer{
    display: flex;
    align-items: flex-start;
}

.activeTab{
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);

    cursor: pointer;

    border-bottom: 2px solid var(--Stroke-onBase-Primary-focused, #D0A9EF);
    color: var(--Foreground-Text-onBase-Accent-rest, #8427CE);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.tabs{
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);

    cursor: pointer;

    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}
