.container{
    width: 318px;
    min-height: 244px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBG-Subtle, #E1DDE3);
    background: var(--Background-Layer-Base-Primary-Rest, #FFF);  
    padding-bottom: 20px;  
}