.container{
    display: flex;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;
}

.optionBox{
    display: flex;
    align-items: center;
    flex: 1 0 0;
    min-width: 130px;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: var(--Background-Component-onLayer-Rest, #FFF);

    display: flex;
    padding: 13px var(--Container, 16px);
    justify-content: center;
    align-items: center;
    gap: -8px;
    flex: 1 0 0;

    color: var(--Foreground-Text-onBase-Subtle-rest, #A49CAB);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    cursor: pointer;
}

.activeOption{
    border-radius: var(--Primary, 8px);
    border: var(--Tertiary, 2px) solid var(--Stroke-onBase-Primary-focused, #D0A9EF);
    background: var(--Background-Component-onLayer-Rest, #FFF);

    color: var(--Foreground-Text-onBase-Accent-rest, #8427CE);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}