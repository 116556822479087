.barImage {
    width: 60px;
    height: 16px;
    border-radius: 50px;
    border: 1px solid #2F4358;
    background: rgba(253, 153, 151, 0.30);
    padding-top: 5px;
}

.yellowIndicator{
    background-color: #FCB50C;
    background-image: linear-gradient(#FCB50C, white);
}

.greenIndicator{
    background-color: #00CC91;
    background-image: linear-gradient(#00CC91, white);  
}

.redIndicator{
    background-color: #FD9997;
    background-image: linear-gradient(#FD9997, white);  
}

.barImage:hover{
    width: 60px;
    height: 24px;
    flex-shrink: 0;
}

.viewMoreContainer{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    padding-bottom: 3px;
}