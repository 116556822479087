.container{
    display: flex;
    height: 226px;
    padding: 0px 93.334px 0px 93.333px;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    background: var(--Background-Page-Base, #F5F3F6);
}

.inviteImg{
    width: 186.559px;
    height: 163.846px;
    flex-shrink: 0;
}