.container{
    display: flex;
    padding: var(--Secondary, 4px) var(--Primary, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--Tertiary, 2px);

    border-radius: var(--Primary, 8px);
    background: var(--Base-Components-Grey-subtle, #FAF9FB);
}

.leftContainer{
    display: flex;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    color: var(--Base-Text-grey-midemp, #66636D);

    /* Label/Label 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.rightContainer{
    display: flex;
    align-items: center;
    gap: var(--Container, 16px);
}

.optionContainer{
    display: flex;
    padding: var(--Secondary, 4px) var(--Primary, 8px);
    align-items: center;
    gap: var(--Primary, 8px);
}

.optionContent{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.iconStyling{
    width: 20px;
    height: 20px;
}