.container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    padding: 16px;
    top: 121px;
    z-index: 111;
    background-color: #EAE7EC;
}

.filterContainer{
    display: flex;
    align-items: center;
    gap: var(--Secondary, 4px);
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.maintext{
    color: #000;
    text-align: right;
  
    /* Title/Title 2 */
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}