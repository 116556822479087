.container{
    display: flex;
    padding: var(--Container, 16px);
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.cardTypes{
    display: flex;
    padding: var(--Container, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Secondary, 4px);
    flex: 1 0 0;

    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.primaryText{
    display: flex;
    gap: 4px;
    
    align-items: flex-end;
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.secondaryText{
    color: var(--Base-Text-grey-highemp, #211F26);

    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.balanced{
    display: flex;
    gap: 2px;
    color: var(--Base-Text-green-midemp, #2A8466);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.unbalanced{
    display: flex;
    gap: 2px;
    color: var(--Base-Text-green-midemp, #CA9416);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px
}

.critical{
    display: flex;
    gap: 2px;
    color: var(--Base-Text-green-midemp, rgb(236, 70, 70));
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}