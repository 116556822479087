*{
  padding :0;
  margin:0;
}

.body {
  overflow-x: hidden;
  position: relative;
}
.header {
  border-radius: 0px 0px 10px 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(58, 51, 94, 0.2);
  max-width:1728px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

.logo-div {
  width: 128px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.patient-div-header {
  height: 96px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.header-divs {
  display: flex;
  align-items: center;
}

.header-second-div {
  display: inline;
}

.header-third-div {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 96px;
  border-radius: 0px 10px 0px 0px;
  background: #fff;
  box-shadow: -2px 0px 0px 0px rgba(58, 51, 94, 0.2);
  padding: 10px;
}

.coach-detail-header {
  width: 100px;
  height: 41.5px;
  margin-left: 10px;
  margin-right: 70px;
}

.profile-image-header {
  width: 56px;
  height: 56px;
}

.dropdown-arrow-header {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0px 6.25px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 10px;
}

.arrow-left-header {
  height: 24px;
  padding: 6.25px 3.25px;
  margin-bottom: 5px;
}

.patient-header {
  color: #2f4358;

  font-family: Fraunces;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.health-coach-name {
  margin-bottom: 0;
  color: #2f4358;

  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.health-coach-type {
  margin-bottom: 0;
  color: rgba(47, 67, 88, 0.5);

  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
}

.gantt-overview-div {
  width: 1200px;
  height: auto;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  position: relative;
  left: 20px;
  right: 16px;
}
.parent {
  position: sticky;
  display: flex;
  flex-direction: row;
  background-color: #f8f7fe;  
}
.gantt-overview-section {
  background-color: #f8f7fe;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 96px;
  width: 100%;
  height: 100%;
}

.manage-action-plan-div {
  position: absolute;
  top: 30px;
  right: 45px;
  width: 200px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1.5px solid #2f4358;
  background: linear-gradient(
      225deg,
      rgba(87, 239, 195, 0.5) 0%,
      rgba(193, 127, 242, 0.5) 51.26%,
      rgba(255, 178, 0, 0.5) 100%
    ),
    #fff;
  box-shadow: 0px 5px 20px 0px #ddc3f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manage-action-plan-span {
  color: #2f4358;
  text-align: center;
  font-family: "Founders Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.gantt-first-div {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gantt-second-div {
  min-height: 420px;
  height: auto;
  display: flex;
  flex-direction: row;
}

.gantt-third-div {
  height: 176px;
  display: flex;
}

.gantt-fourth-div {
  height: 240px;
  display: flex;
}

.gannt-overview-container{
  width:100%;
  min-height: 70px;
}

.gantt-overview {
  color: #2f4358;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  margin-left: 40px;
  margin-top: 20px;
}

.calender {
  width: 980px;
}

.calender-top {
  margin-left: 32px;
  display: flex;
  flex-direction: row;
}

.calender-bottom {
  display: flex;
  flex-direction: row;
}

.left-arrow-div {
  width: 32px;
  height: 56px;
  border-radius: 10px 0px 0px 10px;
  background: rgba(47, 67, 88, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-div {
  width: 896px;
}

.right-arrow-div {
  width: 32px;
  height: 56px;
  border-radius: 0px 10px 10px 0px;
  background: rgba(47, 67, 88, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.calender-top-week-one {
  width: 64px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
  background: #2f4358;
  display: flex;
  justify-content: center;
  align-items: center;
}

.week-one-text {
  color: #fff;

  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.calender-top-week-two {
  margin-left: 384px;
  width: 64px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
  background: #2f4358;
  display: flex;
  justify-content: center;
  align-items: center;
}

.week-two-text {
  color: #fff;

  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.day {
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.month {
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.date {
  display: flex;
  justify-content: center;
  align-items: center;
}

.day-first-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 16px;
}

.day-first-div-text {
  font-size: 8px;
  margin-top: 20%;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}

.month-first-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 12px;
}

.border-month-start {
  border-radius: 10px 10px 10px 10px;
  border-top: 0.5px solid #b9e4d8;
  border-right: 0.5px solid #bbeadc;
  border-bottom: 0.5px solid #bcd6cf;
  background: linear-gradient(
    225deg,
    rgba(87, 239, 195, 0.1) 0%,
    rgba(193, 127, 242, 0.1) 51.26%,
    rgba(255, 178, 0, 0.1) 100%
  );
  opacity: 0.8;
}

.border-month-not-start {
  border-radius: 10px 0 0 10px;
  border-top: 0.5px solid #b9e4d8;
  border-bottom: 0.5px solid #bcd6cf;
  border-left: 0.5px solid #bbeadc;
  background: linear-gradient(
    225deg,
    rgba(87, 239, 195, 0.1) 0%,
    rgba(193, 127, 242, 0.1) 51.26%,
    rgba(255, 178, 0, 0.1) 100%
  );
  opacity: 0.8;
}

.month-first-div-text {
  margin-top: 25%;
  color: #2f4358;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}

.date-first-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
}

.date-first-div-text {
  margin-top: 10%;
  color: #2f4358;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 8px;
  font-style: normal;
  font-weight: 100;
}

.week-text {
  color: #2f4358;

  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.data-stream-div {
  width: 42px;
  height: 320px;
  margin-left: 8px;
  border-radius: 10px 0px 0px 10px;
  border: 2px solid var(--Color-Gradient, #fff);
  background: linear-gradient(
      225deg,
      rgba(87, 239, 195, 0.1) 0%,
      rgba(193, 127, 242, 0.1) 51.26%,
      rgba(255, 178, 0, 0.1) 100%
    ),
    #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
}

.data-stream-text-div {
  transform: rotate(270deg);
  transform-origin: center;
}

.data-stream-text {
  color: #2f4358;

  text-align: center;
  font-family: Founders Grotesk;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
}

.log-name-div {
  width: 199px;
  height: 320px;
}

.log-name-div-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px;
}

.icon-div {
  width: 36px;
  height: 36px;
  border-radius: 10px;
  border: 1.5px solid #57efc3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blood-glucose-log-icon {
  width: 24px;
  height: 24px;
}

.log-name-trend {
}

.log-name {
  width: 117px;
  height: 17px;
  display: flex;
}

.log-name-span {
  color: #2f4358;
  font-family: Founders Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: nowrap;
}

.log-trend {
  width: 60px;
  height: 18px;
  border-radius: 10px;
  border: 1.5px solid #f0dcdc;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.log-trend-span {
  color: #3f3862;

  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.log-name-div-content-last {
  display: flex;
  flex-direction: row;
}

.icon-div-last {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1.5px solid rgba(45, 38, 75, 0.4);
  background: linear-gradient(
      225deg,
      rgba(87, 239, 195, 0.2) 0%,
      rgba(193, 127, 242, 0.2) 51.26%,
      rgba(255, 178, 0, 0.2) 100%
    ),
    #fff;
}

.log-show-div {
  width: 24px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.log-show-text {
  color: #3f3862;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 145.455% */
}

.last-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
}

.show-icons-div {
  width: 24px;
  height: 24px;
  border-radius: 67.5px;
  border: 1.5px solid #57efc3;
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    linear-gradient(
      225deg,
      rgba(87, 239, 195, 0.1) 0%,
      rgba(193, 127, 242, 0.1) 51.26%,
      rgba(255, 178, 0, 0.1) 100%
    );
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -4px;
}

.log-chart-div {
  width: 896px;
}

.log-chart-row {
  margin: 0;
  width: 896px;
  height: 53px;

  text-align: start;
  display: flex;
  flex-direction: row;
}

.log-chart-row-single-tab {
  margin: 0;
  width: 64px;
  height: 53px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bar-image {
  width: 60px;
  height: 16px;
}

.bar-percentage-div {
  width: 48px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar-percentage-span {
  color: #3f3862;

  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
  margin-left: 10px;
  margin-top: 10px;
}

.communications-report-div {
  width: 42px;
  height: 320px;
  margin-left: 8px;
  border-radius: 10px 0px 0px 10px;
  border: 2px solid var(--Color-Gradient, #fff);
  background: linear-gradient(
      225deg,
      rgba(87, 239, 195, 0.1) 0%,
      rgba(193, 127, 242, 0.1) 51.26%,
      rgba(255, 178, 0, 0.1) 100%
    ),
    #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  height: 100%;
}

.communication-report-text-div {
  transform: rotate(270deg);
  transform-origin: center;
}

.communication-report-text {
  color: #2f4358;

  text-align: center;
  font-family: Founders Grotesk;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
}

.sidebar-item-dashboard {
  padding-top: 129px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-item-patients {
  padding-top: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar {
  width: 128px;
  height: auto;
  background-color: #3a335e;
}

.sidebar-item-settings {
  margin-top: 450px;
  position: relative;
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 50px);
}

.sidebar-item-logout {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 47px;
}

.sidebar-text {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.profile_container {
  display: flex;
  flex-direction: column;
}

.profile_container_header {
  background-color: white;
  width: 344px;
  height: fit-content;
  border-radius: 20px;
  margin-left: 16px;
  margin-top: 23px;
}

.pc_image {
  height: 256px;
  width: 256px;
  margin-top: 52px;
}

.pc_img_name {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;
}

.name_text {
  color: #2f4358;
  text-align: center;
  font-family: Fraunces;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  font-weight: bold;
  line-height: 29px;
  margin-top: 25px;
  margin-bottom: 0;
}

.time_text {
  color: rgba(47, 67, 88, 0.5);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 0;
}

.general_info {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 33px;
}

.general_info_text {
  color: #2f4358;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
}

.text_parent {
  color: rgba(47, 67, 88, 0.5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
}

.text_value {
  color: #2f4358;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 4px;
}

.due_date_div {
  margin-top: 12px;
  margin-bottom: 0;
}

.location_div {
  margin-top: 18px;
  margin-bottom: 0;
}

.gtpl_div {
  margin-top: 18px;
  margin-bottom: 32px;
}

.team_info {
  height: fit-content;
  width: 344px;
  background-color: #fff;
  border-radius: 10px;
  margin-left: 16px;
  margin-top: 16px;
}

.care_team_heading {
  color: #2f4358;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 32px;
  margin-left: 34px;
}

.images {
  display: flex;
  flex-direction: row;
  margin-left: 32px;
  margin-top: 16px;
}

.profile_image_two {
  margin-left: -20px;
  border-radius: 80px;
  border: 1.6px solid white;
  height: 44px;
  width: 44px;
}

.profile_image_one {
  border-radius: 80px;
  border: 1.6px solid white;
  height: 44px;
  width: 44px;
}

.invite {
  height: 44px;
  width: fit-content;
  margin-left: 18px;
}

.view-all {
  margin-left: 32px;
  margin-top: 16px;
  margin-bottom: 28.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.view-all-text {
  color: rgba(47, 67, 88, 0.5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  margin-right: 11.25px;
}

.report-notes-div {
  width: 199px;
}

.gantt-chart-second-row-second-column {
  height: 56px;
  margin-top: 5px;
}

.below-reports {
  width: 83px;
  height: 18px;
}

.gantt-second-row-third-column-div {
  margin-top: 3px;
}

.report-div-for-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2px;
}

.image-div-invoice {
  width: 26px;
  height: 40px;
  border-radius: 10px;
  border: 1.5px solid #2d264b;
  background: linear-gradient(
      225deg,
      rgba(87, 239, 195, 0.2) 0%,
      rgba(193, 127, 242, 0.2) 51.26%,
      rgba(255, 178, 0, 0.2) 100%
    ),
    #fff;
}

.report-first-image {
  margin-left: 3px;
  margin-top: 10px;
}

.opacity-div-second-report {
  opacity: 0.4;
}

.last-row-last-column-chart {
  background: linear-gradient(
      225deg,
      rgba(87, 239, 195, 0.1) 0%,
      rgba(193, 127, 242, 0.1) 51.26%,
      rgba(255, 178, 0, 0.1) 100%
    ),
    #fff;
  margin-left: 10px;
}

.first-row-log-chart-div {
  margin-left: 10px;
}

.log-chart-div-second-row {
  margin-left: 6px;
}

.view-more-div {
  width: 60px;
  height: 24px;
  border-radius: 50px;
  border: 1px solid #2f4358;
  background: rgba(253, 153, 151, 0.3);
  gap: 5px;
  display: none;
}

.view-more-div-span {
  color: #3f3862;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  margin-left: 8px;
}

.view-more-div-image {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

#dialogBox {
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 2px solid #f0dcdc;
  display: none;
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 1000;
}

#closeButton {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 25px;
  fill: #555;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-div-dialog-box {
  margin-left: 16px;
}

.date-dialog-box {
  color: #3f3862;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.8;
  margin-top: 8px;
}

.blood-glucose-dialog-box-div {
  width: 178px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  margin-top: -8px;
}

.blood-glucose-dialog-box-text {
  color: #2f4358;
  font-family: Founders Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.glucose-fasting {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-top: 12px;
}

.glucose-range-div {
  width: 75px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.glucose-range-text {
  color: #3f3862;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  opacity: 0.8;
}

.percent-div-dialog {
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.first-div-percent-dialog {
  width: 58px;
  height: 35px;
  margin-right: 35px;
}

.first-div-percent-dialog-span {
  color: #ffb200;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Fraunces;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.8;
}

.second-div-percent-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-div-percent-dialog-first {
  width: 35px;
  height: 35px;
}

.second-div-percent-dialog-first-span {
  color: #20d29f;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Fraunces;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.8;
}

.second-div-percent-dialog-second {
  width: 27px;
  height: 18px;
  margin-bottom: 20px;
}

.second-div-percent-dialog-second-span {
  color: #423e5b;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.time-fasting-div {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-top: -10px;
}

.time-fasting-first {
  width: 77px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.time-fasting-first-span {
  color: rgba(47, 67, 88, 0.5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  text-wrap: nowrap;
}

.time-fasting-second {
  width: 45px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-fasting-second-span {
  color: rgba(47, 67, 88, 0.5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.post-prandials-div {
  width: 111px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-top: 6px;
}

.post-prandials-div-span {
  color: #3f3862;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  opacity: 0.8;
  text-wrap: nowrap;
}

.meal-number {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.meal-number-first {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.meal-number-second {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.meal-number-third {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meal-number-first-p {
  width: 35px;
  height: 35px;
  color: #20d29f;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Fraunces;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  opacity: 0.8;
}

.meal-number-second-p {
  width: 52px;
  height: 35px;
  display: flex;
  justify-content: center;
  color: #ef2929;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Fraunces;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.meal-number-third-p {
  width: 50px;
  height: 35px;
  color: #ef2929;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Fraunces;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  opacity: 0.8;
}

.meal-second-p {
  width: 27px;
  height: 18px;
  color: #423e5b;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  margin-top: 7px;
  margin-left: -5px;
}

.meal-text-div {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-top: -15px;
}

.meal-text-div-inside {
  width: 64px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.meal-text-div-span {
  color: rgba(47, 67, 88, 0.5);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.related-trends-dialog {
  width: 119.5px;
  height: 20px;
  border-radius: 10px;
  border: 1.5px solid rgba(45, 38, 75, 0.4);
  background: linear-gradient(
      225deg,
      rgba(87, 239, 195, 0.2) 0%,
      rgba(193, 127, 242, 0.2) 51.26%,
      rgba(255, 178, 0, 0.2) 100%
    ),
    #fff;
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-top: 13.5px;
}

.related-trends-dialog-first {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.related-trends-dialog-second {
  width: 90px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.related-trends-dialog-second-p {
  color: #3f3862;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
}

.dialog-button {
  width: 200px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1.5px solid #2f4358;
  background: var(
    --Color-Gradient,
    linear-gradient(
      225deg,
      rgba(87, 239, 195, 0.5) 0%,
      rgba(193, 127, 242, 0.5) 51.26%,
      rgba(255, 178, 0, 0.5) 100%
    ),
    #fff
  );
  box-shadow: 0px 5px 20px 0px #ddc3f8;
  margin-left: 50px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.dialog-button-first {
  width: 126px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-button-first-span {
  color: #2f4358;
  text-align: center;
  font-family: Founders Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-wrap: nowrap;
}

/*from here the css of recent activity section starts */
.recent-activity-section {
  position: absolute;
  top: 1038px;
  left: 0px;
  right: 0;
  width: 1730px;
  height: 600px;
  margin: 0;
  flex-shrink: 0;
  background-color: #f8f7fe;
}

.relative-div-recent-activity {
  position: relative;
}

.recent-activity-section-main-div {
  left: 512px;
  top: 5px;
  right: 16px;
  width: 1200px;
  height: 484px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
}

.recent-activity-text-div {
  width: 245px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
  margin-left: 41px;
}

.recent-activity-text-div-span {
  color: #2f4358;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Founders Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.recent-activity-heading-div {
  display: flex;
  align-items: center;
  margin-left: 537px;
  margin-right: 37px;
  height: 25px;
  position: relative;
}

.recent-activity-blood-glucose-div {
  width: 98px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}

.recent-activity-food-log-div {
  width: 97px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}

.recent-activity-movement-div {
  width: 68px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}

.recent-activity-mood-div {
  width: 93px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}

.recent-activity-rest-recovery-div {
  width: 106px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recent-activity-blood-glucose-div-span {
  color: #7f868c;
  font-family: Founders Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  flex-shrink: 0;
}

.recent-activity-food-log-div-span {
  color: #7f868c;
  font-family: Founders Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  flex-shrink: 0;
}

.recent-activity-movement-div-span {
  color: #7f868c;
  font-family: Founders Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  flex-shrink: 0;
}

.recent-activity-mood-div-span {
  color: #7f868c;
  font-family: Founders Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  flex-shrink: 0;
}

.recent-activity-rest-recovery-div-span {
  color: #7f868c;
  font-family: Founders Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  flex-shrink: 0;
}

.recent-activity-black-underline {
  width: 107px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 3px 3px 0px 0px;
  background: #3c4f63;
  position: absolute;
  top: 20px;
  left: -2px;
}

.recent-activity-data {
  position: absolute;
  top: 120px;
  left: 30px;
  width: 400px;
  height: 300px;
}

.recent-activity-data-main-div {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 10px;
}

.recent-activity-data-left-main {
  width: 190px;
  height: 60px;
  display: flex;
  flex-direction: column;
}

.recent-activity-data-middle-main {
  width: 50px;
  height: 40px;
  display: flex;
  flex-direction: column;
}

.recent-activity-data-right-main {
  width: 65px;
  height: 40px;
  display: flex;
  flex-direction: column;
}

.recent-activity-fasting-glucose-div {
  height: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.recent-activity-fasting-glucose-div-span {
  color: #2f4358;
  font-family: Founders Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-wrap: nowrap;
}

.recent-activity-post-prandial-breakfast-text{
  margin-left: 45px;
  cursor: pointer;
}
.recent-activity-post-prandial-lunch-text{
  margin-left: 30px;
  cursor: pointer;
}
.recent-activity-post-prandial-dinner-text{
  margin-left: 30px;
  cursor: pointer;
}

.recent-activity-fasting-glucose-data-div {
  display: flex;
  align-items: center;
}

.recent-activity-fasting-glucose-data-div-first {
  width: 61px;
  height: 23px;
  display: flex;
  padding: 4px 6px;
  border-radius: 20px;
  border: 1px solid #ffb200;
  background: rgba(255, 178, 0, 0.1);
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.recent-activity-fasting-glucose-data-div-first-span {
  color: #3f3862;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-wrap: nowrap;
}

.recent-activity-fasting-glucose-data-div-second {
  width: 113px;
  height: 23px;
  padding: 4px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #ffb200;
  background: #ffb200;
}

.recent-activity-fasting-glucose-data-div-second-span {
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.recent-activity-data-middle-main-first {
  width: 40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recent-activity-data-middle-main-first-span {
  color: #97a1ab;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.recent-activity-data-middle-main-second {
  width: 50px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recent-activity-data-middle-main-second-span {
  color: #000;
  font-family: Founders Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-wrap: nowrap;
}

.recent-activity-time-in-range {
  width: 65px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recent-activity-time-in-range-span {
  color: #97a1ab;
  font-family: Founders Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-wrap: nowrap;
}

.recent-activity-time-in-range-data {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recent-activity-time-in-range-data-span {
  color: #ef2929;
  font-family: Founders Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.recent-activity-data-underline {
  margin-top: -15px;
}

.recent-activity-graph {
  position: absolute;
  width: 780px;
  height: 350px;
  top: -10px;
  left: 370px;
}

.overview-main-div{
  width: 850px;
  position: absolute;
  top: 30px;
  left: 510px;
  display: flex;
  flex-direction: row;
  gap:42px;
}

.overview-text-div{
  width: 90px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  cursor: pointer;
}

.patient-information-div{
  width: 167px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  cursor: pointer;
}

.smart-tracking-div{
  width: 135px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  cursor: pointer;
}

.consent-div{
  width: 269px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  cursor: pointer;
}

.overview-text-div-span{
  color: #2F4358;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Founders Grotesk";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
}

.patient-information-div-span{
  color: #2F4358;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Founders Grotesk";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.smart-tracking-div-span{
  color: #2F4358;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Founders Grotesk";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.consent-div-span{
  color: #2F4358;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Founders Grotesk";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.green-bar-overview{
  position: absolute;
  left: 510px;
  top: 56px;
  width: 90px;
height: 5px;
border-radius: 3px 3px 0px 0px;
background: #84EBC5;
}