.container{
    display: flex;
    padding: 0px 4px;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    margin: 10px;
}

.innerContainer{
    display: flex;
    gap: 6px;
    
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.arrowIcon{
    width: 24px;
    height: 24px;
}