.container{
    display: flex;
    min-width: var(--Circle, 200px);
    padding: var(--Primary, 8px);
    align-items: center;
    gap: var(--Primary, 8px);
    flex: 1 0 0;

    cursor: pointer;
}

.container:hover{
    background-color: #FAF9FB;
}

.textContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.primaryText{
    display: flex;
    color: var(--Base-Text-grey-midemp, #66636D);

    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    gap : 4px
}

.secondaryText{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.mainText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.veryHighIndicator{
    width: 12px;
    height: 12px;
    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Orange-dark, #EE9D63);
    background: var(--Base-Components-Orange-low, #FFF1DB);
}

.highIndicator{
    width: 12px;
    height: 12px;
    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Yellow-dark, #E4A944);
    background: var(--Base-Components-Yellow-low, #FFF6BD);
}

.timeInRangeIndicator{
    width: 12px;
    height: 12px;
    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Green-dark, #7ACCAE);
    background: var(--Base-Components-Green-low, #E5FAF2);
}

.lowIndicator{
    width: 12px;
    height: 12px;
    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Rose-dark, #E093B2);
    background: var(--Base-Components-Rose-low, #FFE9F0);
}

.veryLowIndicator{
    width: 12px;
    height: 12px;
    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Red-dark, #EE9887);
    background: var(--Base-Components-Red-low, #FEEFEB);
}