.container{
    display: flex;
    height: 100%;
    max-width: 408px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    overflow: hidden;

    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}