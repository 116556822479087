.icon{
    width: 16px;
    height: 16px;
}

.optionContainer{
    display: flex;
    gap: 24px;
    align-items: center;
    text-align: center;
    cursor: pointer;
}