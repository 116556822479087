.container{
    display: flex;
    padding: 14px var(--Container, 16px);
    align-items: center;
    gap: 24px;
    align-self: stretch;

    justify-content: space-between;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.leftContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.rightContainer{
    padding: 0px 5px;
}

.primaryLabelTypography{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.headerContentTypography{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.viewDetailsButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);
}

.viewDetailsButtonContainer:hover{
    color: var(--Base-Text-brand-highemp, #33265F) !important;
    border-bottom: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
}

.buttonContentTypo{
    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.redirectIcon{
    width: 16px;
    height: 16px;
}