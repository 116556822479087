.container{
    display: flex;
    min-width: 176px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Tertiary, 2px);
    flex: 1 0 0;
}

.noDataContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}