.mainContainer {
    display: flex;
    padding: var(--space-space-110, 40px);
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.spinnerContainer{
    z-index: 999 !important;
}