.leftContainer{
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.rightContainer{
    width: 462px;
    /* height: 100%; */
    display: flex;
    padding: 48px 0px 0px 64px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    background: var(--Stroke-onBase-Primary-focused, #D0A9EF);
}