.container{
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;

    max-width: 468px;
}

.upperContainer{
    display: flex;
    height: 226px;
    padding: 2px 96px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.lowerContainer{
    display: flex;
    padding: 0px var(--space-space-90, 32px) var(--space-space-90, 32px) var(--space-space-90, 32px);
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.mainIcon{
    width: 171.335px;
    height: 134.866px;
    flex-shrink: 0;
}

.mainTextContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.primaryText{
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk Medium";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%; /* 46.4px */
    letter-spacing: 0.04px;
}

.secondaryText{
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.buttonInnerContainer{
    display: flex;
    padding: 13px var(--space-space-110, 40px);
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 4px;

    background-color: #fff;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

}

.buttonOuterContainer{
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 4px;

    cursor: pointer;

    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}