.container{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 119;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer{
    max-height: 212px !important;
    width: 340px;
    min-height: 212px !important;
}