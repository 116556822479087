.container{
    width: 966px;
    height: auto;
    height: 672px;
    display: flex;
    border-radius: var(--Container, 16px);
    background: var(--Background-Layer-Base-Primary-Rest, #FFF);
    overflow: hidden;
}

.cancelIcon{
    width: 32px;
    height: 32px;
    position: absolute;
    right: 225px;
    padding-bottom: 5px;
}

.modalContainer{
    width: 100%;
    height: 100%;
    background: rgba(21, 18, 23, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightContainerIcon{
    display: flex;
    width: 36px;
    height: 36px;
    padding: var(--Primary, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    border-radius: var(--Primary, 8px);
}