.formContainer{
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 504px;
    height: auto;
    background-color: white;
    
    border-radius: var(--Container, 16px);
    background: var(--Background-Layer-Base-Primary-Rest, #FFF);
}

.inputFieldRow{
    display: flex;
    flex-direction: row;
}

.errorClassName{
    display: flex;
    position: absolute;
    margin-left: 440px ;
    margin-bottom: 100px;
}

.passwordInputField{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* border-right: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED); */
}

.footerContainerClassname{
    gap: 8px;
}

.secondaryHeaderClassname{
    margin-top: 8px;
}

.enabledClassname{
    color: black !important;
    border-radius: var(--Primary, 8px) !important;
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6) !important;
}

.passwordLabelContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.passwordLabelContent{
    color: var(--Foreground-Text-onBase-Low-Emphasis-rest, #7B7382);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.passwordLabelButton{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border: none;
    background-color: #fff;

    border-bottom: 1px solid var(--Stroke-onBase-Accent-rest, #D0A9EF);
    
    color: var(--Foreground-Text-Interactive-Rest, #8427CE);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}