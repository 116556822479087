.container{
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
}

.mealIcon{
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;

    border-radius: var(--Secondary, 4px);
    background: var(--Base-Components-Grey-mid, #EAE7EC);
}

.mealList{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex: 1 0 0;
    flex-wrap: wrap;
}

.mealListItem{
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Grey-low, #F1EFF3);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: none;
}

.emptyImageIcon{
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.emptyImageIconContainer{
    display: flex;
    width: 40px;
    height: 40px;
    padding: 12px;
    justify-content: center;
    align-items: center;

    border-radius: var(--Secondary, 4px);
    background: var(--Base-Components-Grey-mid, #EAE7EC);
}