.container{
    width: 100%;
    height: auto;
}

.primaryColumn{
    max-width: 240px;
}

.tableColumnContainer{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Secondary, 4px);
    flex: 1 0 0;
    align-self: stretch;
    /* width: 418px; */
}

.tablePrimaryColumnContainer{
    padding: 12px 12px;
}

.tablePaddedContainer{
    padding: 12px 24px;
}

.columnDateContainer{
    align-items: center;
}

.tableRow{
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.columnRow{
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Components-Grey-subtle, #FAF9FB);
}

.innerClassName{
    width: 100%;
    padding: 0;
}