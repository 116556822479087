.container{
    display: flex;
    align-items: flex-start;
    gap: var(--Primary, 8px);
    align-self: stretch;
}

.primaryText{
    align-self: stretch;
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.checkBox{
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}

.secondaryText{
    align-self: stretch;
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}