.container{
    display: flex;
    padding: 12px 16px 12px 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    width: 100%;
    height: auto;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.cardIcon{
    display: flex;
    padding: var(--Primary, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
}

.textContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Secondary, 4px);
    flex: 1 0 0;
}

.mainText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;
}

.contentText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.expandIcon{
    display: flex;
    padding: 12px 16px 12px 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.redirectIcon{
    width: 16px;
    height: 16px;
    transform: rotate(-90deg);
}