.container{
    display: flex;
    flex-direction: row;
}

.popupBody{
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;

    border-radius: var(--Primary, 8px);
    background: var(--Background-Layer-Inverted-Secondary-Rest, #29252D);
}

.tipImage{
    width: 12px;
    height: 6px;
    margin-top: 20px;
    flex-shrink: 0;

    fill: var(--Background-Layer-Inverted-Secondary-Rest, #29252D);
}