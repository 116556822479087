.first-div-dialog-box {
    margin-left: 16px;
}

.date-dialog-box {
    color: #3f3862;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Founders Grotesk;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    opacity: 0.8;
    margin-top: 8px;
}

.blood-glucose-dialog-box-div {
    width: 178px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    margin-top: -8px;
}

.blood-glucose-dialog-box-text {
    color: #2f4358;
    font-family: Founders Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.alignLeft{
    transform: translate(-90%,0);
}

.greenText{
    color: #20D29F;
}

.redText{
    color: #EF2929;
}

.yellowText{
    color: #FFB200;
}

.spinner{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}