.container{
    display: flex;
    width: 32px;
    height: 32px;
    padding: 2px 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: var(--Circle, 200px);
    background: var(--Base-Layers-layer-primary, #FFF);

    color: var(--Base-Text-grey-midemp, #66636D);
    text-align: center;
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    cursor: pointer;
}

.container:hover{
    background: var(--Base-Components-Violet-mid, #F4E4FF);
    color: var(--Base-Text-brand-primary, #854DC4);
}