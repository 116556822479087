.container{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.formBodyClassname{
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.formFieldContainer{
    width: 100%;
}

.dropdownContainer{
    overflow-y: scroll;
}