.container{
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;

    border-radius: 8px;
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);

    cursor: context-menu;
}

.blockContainer{
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
}

.secondaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.primaryBlock{
    display: flex;
    align-items: center;
    gap: 4px;
}

.primaryTextTypo{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.contentLabel{
    color: var(--Base-Text-grey-highemp, #211F26);

    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}