.container{
    display: flex;
    padding: 14px 16px;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;

    background: #fff;
}

.icon{
    width: 16px;
    height: 16px;
}

.mainLabel{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.mainContent{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.mainContentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.content{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;

    overflow: hidden;
    color: var(--Base-Text-grey-midemp, #66636D);
    text-overflow: ellipsis;
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.rightContainer{

}