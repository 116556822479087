.container{
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerBackgroundStyles{
    min-height: 100vh;
    height: auto;
    background: #F5F3F6
}

.bodyContainer{
    width: 100%;
    min-height: 100%;
    height: auto;
    display: flex;
    padding-top: 50px;
    justify-content: center;
}