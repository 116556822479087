.searchContainer {
    display: flex;
    min-height: var(--size-navigation-size-sidebar-compact, 76px);
    padding-bottom: var(--space-space-60, 20px);
    align-items: center;
    gap: var(--space-space-70, 24px);
    align-self: stretch;
}

.search {
    display: flex;
    width: 320px;
    align-items: center;
    align-self: stretch;
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
    border-radius: var(--Primary, 8px);


}

.searchLogo {
    display: flex;
    padding: 13px;
    align-items: center;
    border-left: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

}

.searchBar {
    /* display: flex; */
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;
    background: var(--Base-Layers-layer-primary, #FFF);

    border-radius: var(--Primary, 8px);
    border: none;

}

.searchBar::placeholder {
    color: var(--Base-Text-grey-lowemp, #C0BAC7);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

}

.searchBar:focus {

    outline: none;
}