.inputField{
    padding: 0 !important;
    border: none !important;
    display: flex;
    flex-direction: column !important;
}

.quill{
    padding: 0 !important;
    border: none !important;
    display: flex;
    flex-direction: column !important;
}

.ql-toolbar.ql-snow{
    width: 100% !important;
    border-radius: 8px 8px 0px 0px;
}

.ql-container.ql-snow{
    width: 100% !important;
    min-height: 180px !important;
    border-radius: 0px 0px 8px 8px;
}