.body {
 overflow-x: hidden;
}
.header {
 border-radius: 0px 0px 10px 10px;
 background: #fff;
 box-shadow: 0px 4px 4px 0px rgba(58, 51, 94, 0.2);
}

.logo-div {
 width: 128px;
 height: 96px;
 display: flex;
 justify-content: center;
 align-items: center;
}
.patient-div-header {
 width: 1200px;
 height: 96px;
 display: flex;
 justify-content: left;
 align-items: center;
}

.header-divs {
 display: flex;
 align-items: center;
}

.header-second-div {
 display: inline;
}

.header-third-div {
 display: flex;
 justify-content: right;
 align-items: center;
 height: 96px;
 border-radius: 0px 10px 0px 0px;
 background: #fff;
 box-shadow: -2px 0px 0px 0px rgba(58, 51, 94, 0.2);
 padding: 10px;
}

.coach-detail-header {
 width: 100px;
 height: 41.5px;
 margin-left: 10px;
 margin-right: 70px;
}

.profile-image-header {
 width: 56px;
 height: 56px;
}

.dropdown-arrow-header {
 display: flex;
 width: 24px;
 height: 24px;
 padding: 0px 6.25px;
 justify-content: center;
 align-items: center;
 position: relative;
 margin-right: 10px;
}

.arrow-left-header {
 height: 24px;
 padding: 6.25px 3.25px;
 margin-bottom: 5px;
}

.patient-header {
 color: #2f4358;

 font-family: Fraunces;
 font-size: 24px;
 font-style: normal;
 font-weight: 700;
}

.parent-view {
 color: #F8F7FE;
 display: flex;
 flex-direction: row;
 width: 100%;
 height: 100%;
 justify-content: center;
 padding-top: 10px;
}

.sidebar-item-dashboard {
 padding-top: 129px;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.sidebar-item-patients {
 padding-top: 38px;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.sidebar {
 width: 128px;
 height: 100%;
 background-color: #3A335E;
}

.sidebar-item-settings {
 margin-top: 450px;
 position: relative;
 margin-bottom: 34px;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.sidebar-content {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 height: calc(100% - 50px);
}

.sidebar-item-logout {
 margin-top: auto;
 display: flex;
 flex-direction: column;
 align-items: center;
 margin-bottom: 47px;
}

.sidebar-text {
 color: #FFF;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 16px;
 font-style: normal;
 font-weight: 500;
 line-height: 18px;
 margin-top: 8px;
 margin-bottom: 8px;
}

.main-page-view {
 display: flex;
 flex-direction: column;
 width: 90%;
}

.action-plan-heading {
 display: flex;
 flex-direction: row;
 margin-top: 24px;
 margin-left: 32px;
}

.action-plan-heading-profile-image {
 height: 48px;
 width: 48px;
}

.action-plan-heading-name {
 position: relative;
 top: 50%;
 transform: translateY(-50%);
 margin-left: 16px;
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Fraunces;
 font-size: 24px;
 font-style: normal;
 font-weight: bold;
 line-height: 24px; 
}

.action-plan-heading-create-plan {
 position: relative;
 top: 50%;
 transform: translateY(-50%);
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Fraunces;
 font-size: 24px;
 font-style: normal;
 font-weight: 300;
 line-height: 24px; 
}

.action-plan-heading-profile-image {
 border-radius: 10px;
}

.create-action-plan {
 margin-top: 22px;
 margin-left: 24px;
 border-radius: 20px;
 margin-right: 16px;
 border: 1.5px solid #3F3862;
 background: linear-gradient(225deg, rgba(87, 239, 195, 0.10) 0%, rgba(193, 127, 242, 0.10) 51.26%, rgba(255, 178, 0, 0.10) 100%), #FFF;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 width: 98%;
}

.create-action-plan-text {
 margin-left: 36px;
 margin-top: 26px;
 margin-bottom: 26px;
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 24px;
 font-style: normal;
 font-weight: 600;
 line-height: 24px;
}

.create-action-plan-next {
 color: #2F4358;
 text-align: center;
 font-family: Founders Grotesk;
 font-size: 16px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 border-radius: 30px;
 border: 1.5px solid #2F4358;
 margin-top: 16px;
 margin-bottom: 16px;
 padding-top: 12px;
 padding-bottom: 12px;
 padding-left: 84px;
 padding-right: 84px;
 margin-right: 20px;
}

.create-action-plan-step-one {
 border-radius: 20px;
 border: 1.5px dashed #3F3862;
 background: #FFF;
 margin-left: 24px;
 border-radius: 20px;
 margin-right: 16px;
 width: 98%;
}

.create-action-plan-step-one-text {
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Fraunces;
 font-size: 24px;
 font-style: normal;
 font-weight: 700;
 line-height: 24px; 
 margin-top: 33px;
 margin-left: 32px;
}

.create-action-plan-step-one-text-div {
 display: flex;
 flex-direction: row;
 margin-top: 24px;
 margin-left: 32px;
}

.create-action-plan-step-one-text-A {
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 18px;
 font-style: normal;
 font-weight: 700;
 line-height: 24px;
}

.create-action-plan-step-one-text-A-heading {
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 18px;
 font-style: normal;
 font-weight: 400;
 line-height: 24px;
 text-decoration-line: underline;
}

.recommended-div {
 display: flex;
 flex-direction: row;
 margin-top: 27px;
}

.recomended-text {
 border-radius: 10px;
 border: 1.5px solid #2F4358;
 background: linear-gradient(225deg, rgba(87, 239, 195, 0.10) 0%, rgba(193, 127, 242, 0.10) 51.26%, rgba(255, 178, 0, 0.10) 100%), #FFF;
 margin-left: 32px;
 padding-left: 48px;
 padding-right: 48px;
 padding-top: 9px;
 padding-bottom: 9px;
 color: #2F4358;
 text-align: center;
 font-family: Founders Grotesk;
 font-size: 16px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 width: 200px;
}


.drop-image {
 width: 36px;
 height: 36px;
 border-radius: 20px;
 opacity: 0.3;
 background: linear-gradient(225deg, #57EFC3 0%, #C17FF2 51.26%, #FFB200 100%);
 margin-left: 12px;
 padding: 6px;
}

.drop-image-one {
 width: 36px;
 height: 36px;
 border-radius: 20px;
 opacity: 0.3;
 background: linear-gradient(225deg, #57EFC3 0%, #C17FF2 51.26%, #FFB200 100%);
 margin-left: 8px;
 padding: 6px;
}

.recommended-primary-care-problems {
 display: flex;
 flex-direction: row;
 margin-top: 21px;
 margin-left: 32px;
}

.recommended-primary-care-problems-text {
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 18px;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
}

.recommended-primary-care-problems-subtext {
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 12px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
 margin-left: 8px;
 justify-content: center;
}

.recomended-cards {
 display: flex;
 flex-direction: row;
 margin-left: 32px;
 margin-top: 16px;
}

.card-element {
 border-radius: 20px;
 border: 2px solid #F0DCDC;
 display: flex;
 flex-direction: row;
 margin-left: 8px;
}

.card-elements {
 display: flex;
 flex-direction: column;
}

.card-image {
 width: 54px;
 height: 54px;
 border-radius: 15px;
 opacity: 0.3;
 background: linear-gradient(225deg, #57EFC3 0%, #C17FF2 51.26%, #FFB200 100%);
 margin-top: 13px;
 margin-bottom: 13px;
 margin-left: 10px;
}

.card-text-head {
 color: #2F4358;
 font-family: Founders Grotesk;
 font-size: 12.5px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 margin-top: 12px;
 margin-right: 51px;
 margin-left: 10px;
}

.card-text-desc {
 color: #2F4358;
 font-family: Founders Grotesk;
 font-size: 11px;
 font-style: normal;
 font-weight: 500;
 line-height: normal;
 margin-left: 10px;
 margin-top: -15px;
}

.card-yellow-element {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 width: auto;
 margin-right: 17px;
 margin-left: 10px;
 margin-top: -10px;
}

.yellow-dot-text {
 border-radius: 20px;
 border: 2px solid #F0DCDC;
 color: #3F3862;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 12px;
 font-style: normal;
 font-weight: 500;
 line-height: 16px;
 margin-top: 10px;
 padding-left: 8px;
 padding-right: 24px;
}

.recomended-cards-two {
 display: flex;
 flex-direction: row;
 margin-left: 32px;
 margin-top: 16px;
 margin-bottom: 64px;
}

.create-action-plan-step-one-second {
 border-radius: 20px;
 border: 1.5px dashed #3F3862;
 background: #FFF;
 margin-left: 24px;
 border-radius: 20px;
 margin-right: 16px;
 width: 98%;
 margin-top: 24px;
}

.create-action-plan-step-one-third {
 border-radius: 20px;
 background: #FFF;
 color: #FFF;
 margin-left: 24px;
 border-radius: 20px;
 margin-right: 16px;
 width: 98%;
 margin-top: 24px;
 border: 1.5px solid #fff
}

.recomended-text-pathways {
 border-radius: 10px;
 border: 1.5px solid #2F4358;
 background: linear-gradient(225deg, rgba(87, 239, 195, 0.10) 0%, rgba(193, 127, 242, 0.10) 51.26%, rgba(255, 178, 0, 0.10) 100%), #FFF;
 margin-left: 32px;
 padding-left: 14px;
 padding-right: 14px;
 padding-top: 9px;
 padding-bottom: 9px;
 color: #2F4358;
 text-align: center;
 font-family: Founders Grotesk;
 font-size: 16px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 width: 250px;
}

.recommended-buttons {
 display: flex;
 flex-direction: row;
}

.create-your-path-text {
 color: #2F4358;
 text-align: center;
 font-family: Founders Grotesk;
 font-size: 16px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 border-radius: 10px;
 background: linear-gradient(225deg, rgba(87, 239, 195, 0.10) 0%, rgba(193, 127, 242, 0.10) 51.26%, rgba(255, 178, 0, 0.10) 100%), #FFF;
 margin-left: 10px;
 padding-left: 11px;
 padding-right: 11px;
 padding-top: 9px;
 padding-bottom: 9px;
}

.select-primary-problem {
 border-radius: 20px;
 border: 2px dashed #F0DCDC;
 opacity: 0.6;
 margin-top: 24px;
 margin-left: 32px;
 margin-bottom: 65px;
}

.select-primary-problem-text {
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Caveat;
 font-size: 16px;
 font-style: normal;
 font-weight: 700;
 line-height: 24px;
 text-decoration-line: underline;
 margin-top: 30px;
 margin-bottom: 30px;
 margin-left: 29px;
}

.recommended-pathways-cards {
 margin-top: 24px;
 margin-left: 27px;
 display:flex;
 flex-direction: row;
}

.recommended-pathways-cards-item {
 border-radius: 20px;
 background: #FFF;
 box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
 display: flex;
 flex-direction: column;
}

.recommended-pathways-cards-item-second {
 border-radius: 20px;
 background: #FFF;
 box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
 display: flex;
 flex-direction: column;
 margin-left: 16px;
}

.recommended-pathways-cards-image {
 border-top-left-radius: 20px;
 border-top-right-radius: 20px;
 height: 130px;
 width: auto;
}

.recommended-pathways-card-heading {
 color: #2F4358;
 font-family: Founders Grotesk;
 font-size: 14px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 margin-top: 8px;
 margin-left: 32px;
}

.recommended-pathways-card-sub-heading {
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 12px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
 margin-top: -15px;
 margin-left: 32px;
}

.recommended-pathways-cards-duration {
 margin-left: 32px;
 display: flex;
 flex-direction: row;
 margin-top: -10px;
}

.recommended-pathways-cards-duration-text {
 color: #3F3862;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 10px;
 font-style: normal;
 font-weight: 400;
 line-height: 16px;
}

.recommended-pathways-cards-duration-text-value {
 opacity: 0.8;
 color: #3F3862;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 10px;
 font-style: normal;
 font-weight: 700;
 line-height: 16px;
 margin-right: 46px;
}


.recommended-pathways-cards-bottomtext-buttons {
 display: flex;
 flex-direction: row;
 width: auto;
 justify-content: space-between;
 margin-left: 32px;
 margin-right: 32px;
}

.preview {
 color: #2F4358;
 text-align: center;
 font-family: Founders Grotesk;
 font-size: 14px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 border-radius: 20px;
 border: 1.5px solid #2F4358;
 box-shadow: 0px 5px 20px 0px #DDC3F8; 
 margin-bottom: 24px;
 padding-left: 16px;
 padding-right: 16px;
 padding-top: 10px;
 padding-bottom: 10px;
 height: 36px;
}

.select-this-pathway {
 border-radius: 20px;
 border: 1.5px solid #2F4358;
 background: var(--Color-Gradient, linear-gradient(225deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 51.26%, rgba(255, 178, 0, 0.50) 100%), #FFF);
 box-shadow: 0px 5px 20px 0px #DDC3F8;
 color: #2F4358;
 text-align: center;
 font-family: Founders Grotesk;
 font-size: 14px;
 font-style: normal;
 font-weight: 600;
 line-height: normal;
 padding-left: 16px;
 padding-right: 16px;
 padding-top: 10px;
 padding-bottom: 10px;
 justify-content: center;
 height: 36px;
}

.one-thing-pathway {
 display: flex;
 flex-direction: row;
 margin-left: 32px;
 margin-top: 32px;
}

.one-thing-pathway-text {
 color: #2F4358;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 18px;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
}

.one-thing-pathway-text-edit {
 color: #3F3862;
 font-feature-settings: 'clig' off, 'liga' off;
 font-family: Founders Grotesk;
 font-size: 12px;
 font-style: normal;
 font-weight: 500;
 line-height: 16px;
 border-radius: 10px;
 border: 1.5px solid #F0DCDC;
 opacity: 0.6;
 padding-left: 20px;
 padding-right: 20px;
 margin-left: 7px;
 padding-top: 1.5px;
 padding-bottom: 0.5px;
}

.action-plan-notes-text {
 color: var(--edit-tabel-header, #686770);
 font-family: Founders Grotesk;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 160%; 
 margin-left: 32px;
}

.action-plan-edit-box {
 color: var(--Nab-bar, #888788);
 font-family: Founders Grotesk;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: 160%;
 border-radius: 4px;
 border: 1px solid var(--Line-stock, #EAEAF0);
 display: flex;
 padding: 16px;
 flex-direction: column;
 align-items: flex-start;
 gap: 10px;
 width: 480px;
 height: 132px;
 margin-left: 32px;
 margin-bottom: 69px;
}