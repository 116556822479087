.container {
    display: flex;
    padding: var(--space-space-110, 40px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-space-110, 40px);

    border-radius: var(--Container, 16px);
    background: var(--Background-Layer-Base-Primary-Rest, #FFF);

    display: inline-flex;
    align-items: flex-start;
}