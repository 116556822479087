.practiceInfo {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
}

.mainContainer{
    width: 100%;
}

.container {

    display: flex;
    min-width: 548px;
    padding: var(--space-space-110, 40px);
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;


    border-radius: 8px;
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.practiceDetail {
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Headline/Headline 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
    /* 29.76px */
    letter-spacing: 0.096px;

    display: flex;
    padding-bottom: var(--space-space-60, 20px);
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.field {}

.fields {
    display: flex;
    padding-bottom: var(--space-space-60, 20px);
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.fieldType {
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.fieldValue {
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Label/Label 3 */
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
}

.contactNumber {
    color: var(--Base-Text-brand-midemp, #7350B9);

    /* Label/Label 2 */
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);

    width: fit-content;
}

.fieldHeading {
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Label/Label 3 */
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;


}

.practiceEdit {
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 2 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-mid, #D2CDD7);

    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}


.allWorksites {
    /* Headline/Headline 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
    /* 29.76px */
    letter-spacing: 0.096px;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
}


.allWorksites::before {
    content: '';
    display: inline-block;
    height: 1px;
    background: var(--Base-Stroke-Grey-low, #DCD8E0);
    vertical-align: super;
    width: 16px;
    margin-right: 8px;
}

.allWorksites::after {
    content: '';
    display: inline-block;
    height: 1px;
    background: var(--Base-Stroke-Grey-low, #DCD8E0);
    vertical-align: super;
    width: 87.9%;
    border-radius: 1px solid black;
    margin-left: 8px;
}

.worksite{
    display: flex;
min-width: 556px;
padding: var(--space-space-110, 40px);
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;

border-radius: 8px;
border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
margin-bottom: 24px;

}

.worksiteHeading{
    color: var(--Base-Text-grey-highemp, #211F26);

/* Headline/Headline 2 */
font-family: "Founders Grotesk Medium";
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 124%; /* 29.76px */
letter-spacing: 0.096px;
}

.worksiteContainer{

display: flex;
padding-bottom: var(--space-space-60, 20px);
align-items: flex-start;
align-content: flex-start;
gap: 24px;
align-self: stretch;
flex-wrap: wrap;

}

.worksiteId{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);

/* Title/Title 3 */
font-family: "Founders Grotesk";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 128%; /* 25.6px */
letter-spacing: 0.08px;
}

.worksiteHeadingContainer{
    display: flex;
padding-bottom: var(--space-space-60, 20px);
justify-content: space-between;
align-items: baseline;
align-self: stretch;
}

.worksiteField{
    display: flex;
min-width: 508px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 4px;
flex: 1 0 0;


}

.worksiteLayout{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;

}

.worksiteFields {
    display: flex;
    padding-bottom: var(--space-space-60, 20px);
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    width: 50%;
}

.worksiteFieldsSecondary{

    display: flex;
    padding-bottom: var(--space-space-60, 20px);
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    height: fit-content;
}