.container{
    height: 40px;
    width: 131px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
    cursor: pointer;
}

.innerContainer{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: var(--Primary, 8px);
    display: flex;
    justify-content: center;
    padding-top: 6px;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}