.container{
    display: flex;
    padding: var(--Container, 16px) 24px;
    align-items: flex-start;
    align-self: stretch;

    border-bottom: 1px solid var(--Stroke-onBase-Subtle, #F5F3F6);
}

.noData{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.textContainer{
    display: flex;
    flex-direction: column;
}

.primaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}

.leftContainer{
    display: flex;
    min-width: 176px;
    height: 100%;
    padding: var(--Primary, 8px) var(--Container, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
}

.contentCard{
    display: flex;
    padding: var(--Primary, 8px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Grey-low, #F1EFF3);
}

.contentCardText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.imgContainer{
    display: flex;
    width: 160px;
    height: 160px;
    justify-content: center;
    align-items: center;

    border-radius: var(--Secondary, 4px);
    background: var(--Base-Components-Grey-mid, #EAE7EC);
}

.labelTypo{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}

.mainText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}