.container{
    display: flex;
}

.textIcon{
    display: flex;
    width: 36px;
    height: 36px;
    padding: 13px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    border-radius: var(--Secondary, 4px);
    background: var(--Stroke-onAccent-Rest, #3A335E);
}

.popupContainer{
    position: absolute;
    margin-left: 50px;
}

.popupBodyClassname{
    background-color: #fff;
}