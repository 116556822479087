.container{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 24px;
}

.noText{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.primaryContainer{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px var(--Container, 16px);
    align-self: stretch;
    flex-wrap: wrap;

}


.primaryLabelText{
    display: flex;
    align-items: flex-end;
    gap:4px;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.084px;

}

.valueText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.dataCard{
    display: flex;
    min-width: 258px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Tertiary, 2px);
    flex: 1 0 0;
}

.mainSpinner{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.viewMoreDetailsButton{
    width: 100%;
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.224px;
}