.checkBoxContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;
    width: 100%;
    display: flex;
}

.checkbox {
    margin: 4.5px;
    margin-right: 0px;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}

.checkboxData {
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.064px;
}