.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border-bottom: 1px solid var(--Stroke-onBase-Subtle, #F5F3F6);
}

.listItemOptionsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border-bottom: 1px solid var(--Stroke-onBase-Subtle, #F5F3F6);
}

.mainContentTypo{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}

.listIemContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border-bottom: 1px solid var(--Stroke-onBase-Subtle, #F5F3F6);
}