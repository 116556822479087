.container{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.buttonContainer{
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    background: var(--Base-Components-Grey-mid, #EAE7EC);

    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.textPrimaryTypo{
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.textLinkTypo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);

    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    cursor: pointer;
}

.mainTextContainer{
    display: flex;
    gap: 2px;
}

.activeButton{
    border-radius: var(--Primary, 8px) !important;
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6) !important;

    color: var(--Base-Text-grey-highemp, #211F26) !important;
    font-family: "Founders Grotesk Medium" !important;
}