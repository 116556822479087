.container{
    height: 100%;
}

.actionField{
    max-width: 130px;
    width: 130px;
    display: flex;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}

.tableRowEffect{
    padding: 17px 0px;
    border-bottom: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
}

.tableRowEffect:hover {
    background-color: #FCF8FF;
}

.columnsContainer{
    width: 262px;
    justify-content: flex-start;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.innerClassName{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-style: normal;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    font-size: 14px;
    font-weight: 400;
}
