.cardHeader{
    display: flex;
    padding: var(--Container, 16px);
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.cardHeaderContent{
    display: flex;
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);

    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.cardHeaderImage{
    display: flex;
    width: 46x !important;
    height: 46px;
    max-width: 46px;
    max-height: 46px;
    justify-content: center;
    align-items: center;

    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Stroke-onBase-Primary-hover, #E1DDE3);
}

.cardContentContainer{
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-bottom: 0px;
    width: 100%;
    gap: 8px;
}

.cardContentText{
    width: 100%;

    color: var(--Foreground-Text-onBase-Subtle-rest, #A49CAB);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.optionsContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
    overflow-wrap:break-word;
    flex-wrap: wrap;
}

.option{
    display: flex;
    min-width: 90px;
    width: auto;
    min-height: 32px;
    height: auto;
    padding: 6px 6px 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Circle, 200px);
    background: var(--Background-Component-Muted-Light-Layer-Rest, #CCC8D0);
}