.container{
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-left: 32px;
}

.setupActions{
    display: flex;
    gap: 10px;
}

.setupButtonContainer{
    width: 95px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(240,150,189);
    background: radial-gradient(circle, rgba(240,150,189,1) 0%, rgba(148,197,233,0.9640231092436975) 97%);    /* gap: 8px; */
    border-radius: 8px;
    gap: 8px;

    cursor: pointer;
}

.setupButton{
    border: 2px solid #F5F3F6;
    padding: 4px 16px 6px 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    background-color: #FFF;
    padding: auto;
    width: 88px;
    height: 40px;
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.infoButton{

    display: flex;
    padding: 10px var(--Container, 16px);
    align-items: center;
    gap: var(--Secondary, 4px);

    border-radius: var(--Secondary, 4px);
    border: 1px solid #84EBC5;
    background: rgba(132, 235, 197, 0.08);

    color: #1DAF79;
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.redirectArrow{
    width: 20px;
    height: 20px;
}

.textContainer{
    align-self: stretch;
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.linkButton{
    background-color: #FFF;
    border: 1px solid transparent;
    color: var(--Foreground-Text-Interactive-Rest, #8427CE);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.infoIcon{
    width: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltipContainer{
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}

.tooltipContent{
    align-self: stretch;
    color: var(--Foreground-Text-onInverted-Medium-Emphasis-rest, #CCC8D0);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    display: flex;
    align-items: flex-start;
}

.tooltipLabel{
    color: var(--Foreground-Text-onInverted-High-Emphasis-rest, #FAF9FB);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    display: flex;
    align-items: flex-start;
}