.container{
    min-height: 64px;
    max-height: 64px;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: auto 10px;
    padding-left: 80px;
    box-shadow: 0px 4px 4px 0px rgba(58, 51, 94, 0.20);
    position: fixed;
    z-index: 121;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.logOutClass{
    
}