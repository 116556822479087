.container{
    display: flex;
    padding: var(--Container, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;
}

.submitButton{
    display: flex;
    padding: 13px var(--Container, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    background: var(--Base-Components-Grey-mid, #EAE7EC);

    cursor: pointer;

    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.inputContainerClass{
    width: 100% !important;
}

.activeStyles{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    letter-spacing: 0.064px;    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.dateLabel{
    display: flex;
    padding: 0px var(--Primary, 8px);
    align-items: center;
    gap: var(--Secondary, 4px);
    align-self: stretch;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.dateContainer{
    display: flex;
    height: 72px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Secondary, 4px);
    align-self: stretch;
}

.react-datepicker {
    width: 100% !important;
    max-width: 100% !important;
}

.react-datepicker__tether-element {
    width: 100% !important;
}

.inputDate{
    border: 1px solid transparent;
    width: 420px;

    display: flex;
    padding: 13px var(--Container, 16px);
    align-items: center;
    align-self: stretch;
    flex: 1 0 0;

    border-radius: 8px 0 0 8px;
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
}

.datePickerIconContainer{
    display: flex;
    padding: 13px;
    align-items: center;
    height: 52.17px;

    border-radius: 0 8px 8px 0;
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
}

.datePickerInputContainer{
    display: flex;
    align-items: center;
    align-self: stretch;
}

.calenderIcon{
    width: 22px;
    height: 22px;
}

.inputDate:focus{
    border: 1px solid transparent;
    outline: none;
}