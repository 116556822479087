.container{
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    align-self: stretch;

    border-radius: 12px;
    background: var(--Base-Layers-layer-primary, #FFF);
}

.chartContainer{
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Secondary, 4px);
    flex: 1 0 0;

    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);

}

.labelContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    padding: 16px 24px;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-tertiary, #FAF9FB);
}

.label{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.lowerTextContainer{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.mainTableBody{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 24px;
    align-items: center;
    align-self: stretch;
}

.mainContentContainer{
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.contentLabel{
    display: flex;
    align-items: flex-start;
    gap: 4px;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.contentSuffix{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
}

.contentValue{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.contentValueContainer{

    display: flex;
    align-items: center;
    gap: 4px;
    
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.red{
    border-left: 4px solid var(--Base-Components-Green-dark, #EE9887);
}

.green{
    border-left: 4px solid var(--Base-Components-Green-dark, #7ACCAE);
}

.yellow{
    border-left: 4px solid var(--Base-Components-Green-dark, #7ACCAE);
}

.cardMainValue{
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.lowerContainerText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.secondaryTextClass{
    flex: none;
}

.noDataContainerClass{
    width: auto;
    min-height: 215px;
    display: flex;
    justify-content: center;
}

.noDataContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    text-align: center;
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.noDataTypo{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.alertContainer{
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.alertContent{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.alertMetricIcon{
    width: 16px;
    height: 16px;
}

.greenContent{
    color: var(--Base-Text-green-midemp, #2A8466) !important;
}

.redContent{
    color: var(--Base-Text-red-midemp, #AE4B37) !important;
}

.noData{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}