.heading {
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Display/Display 3 */
    font-family: "Founders Grotesk Medium";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%;
    /* 46.4px */
    letter-spacing: 0.04px;

    align-self: stretch;


    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    gap: var(--space-space-40, 12px);
    flex: 1 0 0;

    /* display: flex; */
    padding-bottom: var(--space-space-130, 48px);
    /* flex-direction: column; */


}

.practiceLogo{
    color: var(--Base-Text-grey-highemp, #211F26);
text-align: center;

/* Body/Body 2 */
font-family: "Founders Grotesk Medium";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 22.4px */
letter-spacing: 0.064px;

width: var(--size-square-size-square-130, 48px);
flex-shrink: 0;

display: flex;
width: var(--size-square-size-square-130, 48px);
height: var(--size-square-size-square-130, 48px);
padding: 13px 0px;
justify-content: center;
align-items: center;

border-radius: var(--Circle, 200px);
border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.practiceName{
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Display/Display 3 */
    font-family: "Founders Grotesk Medium";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%;
    /* 46.4px */
    letter-spacing: 0.04px;

    align-self: stretch;


    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    gap: var(--space-space-40, 12px);
    flex: 1 0 0;

    /* display: flex; */
    /* padding-bottom: var(--space-space-130, 48px); */
    /* flex-direction: column; */
}

.moveToArchive{

    display: flex;
    width: var(--size-navigation-size-sidebar, 240px);
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;


position: absolute;
right: 40px;
top: 194px;

border-radius: var(--Secondary, 4px);
background: var(--Base-Layers-layer-primary, #FFF);

/* Shadow/Dropdown */
box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}

.moveToArchiveContainer{
    color: var(--Base-Text-grey-midemp, #66636D);

/* Body/Body 3 */
font-family: "Founders Grotesk";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
letter-spacing: 0.064px;

display: flex;
width: var(--size-navigation-size-sidebar, 240px);
padding: 8px;
justify-content: left;
align-items: center;
gap: 8px;
}

.moveToArchiveSpace{
    display: flex;
    width: 51px;
    height: 20px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
}

.buttonExport {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    /* Body/Body 2 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-mid, #D2CDD7);

}

.buttonAddNewPractice {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 2 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);



}

.buttonAddNewPractice:hover {
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Hover, linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), #F5F3F6);
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--space-space-40, 12px);

    display: flex;
    padding-left: var(--space-space-70, 24px);
    justify-content: flex-end;
    align-items: flex-start;
}