.container {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: var(--Base-Text-grey-highemp, #211F26);

    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);

    /* Body/Body 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    cursor: pointer;
}

.inActive{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    border-radius: var(--Primary, 8px);
    background: var(--Base-Components-Grey-mid, #EAE7EC);

    cursor: not-allowed;
}

.container:hover {

    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;
}