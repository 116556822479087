.container{
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s ease;

  border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.container.hovered {
  background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.leftContainer{
  display: flex;
}

.green{
  color: var(--Base-Text-green-midemp, #2A8466) !important;
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
}

.red{
  color: var(--Base-Brand-yellow-hover, #ce2f2f) !important;

  /* Label/Label 2 */
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
}

.yellow{
  color: var(--Base-Brand-yellow-hover, #CA9416) !important;
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
}

.dateDataDiv{
  width: 104px;
  padding: 12px var(--Container, 16px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  color: var(--Base-Text-grey-midemp, #66636D);
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
}

.mainContainer{
  display: flex;
  max-width: 144px;
  padding: 12px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Secondary, 4px);
  flex: 1 0 0;
  align-self: stretch;
}

.plateScoreText{
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--Base-Text-grey-highemp, #211F26);
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
}

.modalMainContainer{
  max-width: 800px;
}

.modalContainer{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding-top: 60px;
  overflow: scroll;
}

.dateDataContent
{
  color: var(--Base-Text-grey-highemp, #211F26);
  font-family: "Founders Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.064px;
}

.mealDataDiv{
display: flex;
width: 334px;
padding: 12px;
align-items: center;
gap: var(--Primary, 8px);
align-self: stretch;
}

.mealImage{
  width: 32px;
height: 32px;
flex-shrink: 0;
border-radius: var(--Secondary, 4px);
}

.mealName{
  display: flex;
  align-items: center;
  align-content: center;
  gap: 1px;
  flex: 1 0 0;
  flex-wrap: wrap;
  color: var(--Base-Text-grey-highemp, #211F26);
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
}

.statusDataDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  gap: var(--Primary, 8px);
  min-width: 115.7px;

  max-width: 144px;
}

.foodItem{
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: var(--Secondary, 4px);

  border-radius: var(--Secondary, 4px);
  border: 1px solid var(--Base-Components-Grey-low, #F1EFF3);

  color: var(--Base-Text-grey-midemp, #66636D);
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
}

.micronutrientDataDiv{
  display: flex;
  min-width: 100px;
  padding: 12px 24px;
  align-items: center;
  gap: var(--Primary, 8px);
  flex: 1 0 0;
  align-self: stretch;
}

.micronutrientDataContent{
  color: var(--Base-Text-grey-highemp, #211F26);

/* Label/Label 3 */
font-family: "Founders Grotesk";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
letter-spacing: 0.084px;
}

.rightArrowDiv{
display: flex;
padding: 12px 10px;
align-items: center;
gap: var(--Primary, 8px);
align-self: stretch;
cursor: pointer;
}

.mealTitle{
  color: var(--Base-Text-grey-highemp, #211F26);
  font-family: "Founders Grotesk Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.064px;
  
  white-space: nowrap;
  
  width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mealDescription{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  white-space: nowrap;
  
  width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--Base-Text-grey-midemp, #66636D);
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.084px;
}