.container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    background: var(--Base-Layers-layer-primary, #FFF);
    padding: 24px;
    gap: 40px;
}

.innerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--Base-Layers-layer-primary, #FFF);
    gap: 40px;
}