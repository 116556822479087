.outerContainer{
    display: flex;
    padding: var(--Primary, 8px) var(--Container, 16px);
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.mainTypography{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.dateContent{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.dropdown{
    width: 20px;
    height: 20px;

    cursor: pointer;
}

.calendarClass{
    z-index: 1;
    right: auto;
}

.dateContainer{
    display: flex;
    align-items: center;
    gap: var(--Tertiary, 2px);
}

.notesTypeContainer{
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;

    cursor: pointer;
}

.notesButton{
    display: flex;
    align-items: center;
    width: auto;
    overflow: hidden;

    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.leftButton{
    border-right: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.nonActiveOption{
    display: flex;
    padding: 1px 10px 1px 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    cursor: pointer;
}

.nonActiveOption:hover{
    background-color: #FCF8FF;
    color: #211F26;
}

.activeOption{
    color: #854DC4;
    background: var(--Base-Components-Grey-subtle, #FAF9FB);
}

.activeOption:hover{
    color: #33265F !important;
}