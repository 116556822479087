.container{
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Components-Grey-subtle, #FAF9FB);
}

.label{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.exclamationIcon{
    width: 16px;
    height: 16px;
}

.innerContainer{
    display: flex;
    align-items: center;
    gap: 4px;
}

.tooltipContainer{
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    width: 204px;

    border-radius: var(--Primary, 8px);
    background: var(--Background-Layer-Inverted-Secondary-Rest, #29252D);
}

.tooltipTitle{
    color: var(--Foreground-Text-onInverted-High-Emphasis-rest, #FAF9FB);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    width: 100%;
}

.tooltipContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    width: 100%;
    color: var(--Foreground-Text-onInverted-Medium-Emphasis-rest, #CCC8D0);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}