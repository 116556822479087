.container{
    display: flex;
    padding: var(--Container, 16px);
    gap: var(--Container, 16px);
    align-self: stretch;
    width: auto;
    min-height: 135px;
    height: 100%;

    background: var(--Base-Layers-layer-primary, #ffffff);
}

.leftContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--Container, 16px);
    flex: 1 0 0;
}

.rightContainer{
    min-width: 175px;
}

.statField{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 176px;
}

.valueContainer{
    display: flex;
    gap : 5px
}

.valueText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%;
    letter-spacing: 0.08px;
}

.labelText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.084px;
}
