.container{
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.radioButton{
    width: 20px;
    height: 20px;
}

.externalContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: var(--Container, 16px);
}

.nonActiveRadios{
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}
