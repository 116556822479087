.container{
    display: flex;
    padding: 10px var(--Container, 16px);
    align-self: stretch;

    border-radius: 0px 0px 12px 12px;
    border-top: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);

    gap: 4px;
}

.footerText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}