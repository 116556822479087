.container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;

}

.mainContent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);
    cursor: pointer;
}

.mainContent:hover{
    border-bottom: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
}

.linkButton{
    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    cursor: pointer;
}

.linkButton:hover{
    color: var(--Base-Text-brand-highemp, #33265F);
}

.moveToArchive{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    width: 240px;

    cursor: pointer;
    position: absolute;
    margin-top: 25px;

    border-radius: var(--Secondary, 4px);
    background: var(--Base-Layers-layer-primary, #FFF);

    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}

.moveToArchiveContainer{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    display: flex;
    width: 100%;
    padding: 8px;
    justify-content: left;
    align-items: center;
    gap: 8px;
}

.moveToArchiveContainer:hover{
    border-radius: var(--Tertiary, 2px);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}


.buttonGroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--space-space-40, 12px);

    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.buttonExport {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;

    cursor: pointer;
}