.balancedContainer{
display: flex;
padding: 2px 8px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: var(--Secondary, 4px);
background: var(--Base-Components-Green-low, #E5FAF2);
min-width: 70px;
max-width: 70px;
}

.balanced{
  color: var(--Base-Text-green-midemp, #2A8466);

/* Caption/Caption 3 */
font-family: "Founders Grotesk";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 148%; /* 17.76px */
letter-spacing: 0.072px;
}



.unbalancedContainer{
  display: flex;
padding: 2px 8px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: var(--Secondary, 4px);
background: var(--Base-Components-Yellow-subtle, #FFFBE0);
min-width: 70px;
max-width: 70px;
}

.unbalanced{
  color: var(--Base-Text-yellow-midemp, #86612D);

/* Caption/Caption 3 */
font-family: "Founders Grotesk";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 148%; /* 17.76px */
letter-spacing: 0.072px;
}


.criticalContainer{
  display: flex;
padding: 2px 8px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: var(--Secondary, 4px);
background: var(--Base-Components-Yellow-subtle, #f7e2e2);
min-width: 70px;
max-width: 70px;
}

.critical{
  color: var(--Base-Text-yellow-midemp, #ff0000);

/* Caption/Caption 3 */
font-family: "Founders Grotesk";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 148%; /* 17.76px */
letter-spacing: 0.072px;
}