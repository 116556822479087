.container{
    display: flex;
    height: 20px;
    justify-content: center;
    align-items: center;
    gap: var(--Secondary, 4px);

    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);
}

.typography{

    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.224px;

}