.container{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-space-110, 40px);
    align-self: stretch;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.topMainText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.containerHeader{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
}

.resendInvite{
    margin-left: 2.5px;
    color: rgb(159, 214, 240);
    cursor: pointer;
}

.topHeader{
    align-self: stretch;
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%; /* 46.4px */
    letter-spacing: 0.04px;
}

.bottomHeader{
    align-self: stretch;
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.checkBoxContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;
    width: 100%;
}

.pinContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.mainPrimaryTextTypo{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.bodyContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.footerButton{
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    background: var(--Background-Component-onLayer-Disabled, #EBE8ED);

    color: var(--Foreground-Text-onBase-Disabled, rgba(21, 18, 23, 0.48));
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.cardFooterContainer{
    display: flex;
    flex-direction: column;
    gap: 10px
}

.footerContent{
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    /* word-spacing : 1.5px; */

    display: flex;
    width: 100%;
    padding-top: 2px;
    justify-content: center;
}

.checkbox{
    margin: 4.5px;
    margin-right:0px ;
}

.pinCodeMiniContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inputField{
    display: flex;
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: var(--Background-Component-onLayer-Rest, #FFF);

    -webkit-appearance: none;

    width: 60px;
    height: 48px;
    margin: 3.5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.enabled{
    color: #1A171C;
    border: 2px solid transparent;
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}