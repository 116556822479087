.container{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    min-width: 500px;
    max-height: 500px;
    max-width: 500px;
    overflow-y: scroll;

    border-bottom: 1px solid var(--Stroke-onBase-Subtle, #F5F3F6);
}

.container::-webkit-scrollbar {
    width: 10px; /* Width of the vertical scrollbar */
    height: 10px; /* Height of the horizontal scrollbar */
}

.container::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 5px; /* Rounded corners */
}

.container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Thumb color on hover */
}

.container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track background */
}