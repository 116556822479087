.container{
    display: flex;
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    gap : 3px;
    width: 100%;
    height: 100%;
    align-items: center;
    padding-left: 10px;
}

.icon{
    width: 16px;
    height: 16px;
}

.success{
    color: var(--Base-Text-green-midemp, #2A8466);
    margin-top: 5px;
    gap: 10px;
}

.normalText{
    margin-top: 5px;
}