.container{
    display: flex;
    padding: 14px 16px;
    align-items: center;
    gap: 2px;
    align-self: stretch;
    height: 72px;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.leftContainer{
    display: flex;
    flex-direction: column;
    gap: var(--Tertiary, 2px);
    flex: 1 0 0;
}

.primaryText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.secondaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Secondary, 4px);

    cursor: pointer;

    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);

    color: var(--Base-Text-brand-midemp, #7350B9);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.button:hover{
    color: var(--Base-Text-brand-highemp, #33265F) !important;
    border-bottom: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
}