.cardContainer{
    display: flex;
    padding: var(--Primary, 8px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    flex: 1 0 0;

    max-width: 504px;
    height: 512px;

    border-radius: var(--Primary, 8px);
    background: var(--Background-Page-Base, #FFF);
}

.imageContainer{
    display: flex;
    height: 226px;
    padding: 2px 96px;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    background: var(--Background-Page-Base, #F5F3F6);

}

.cardImage{
    min-width: 171.336px;
    min-height: 134.866px;
    flex-shrink: 0;
}

.cardTextContainer{
    display: flex;
    padding: 0px 32px 32px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.bodyHeading{
    align-self: stretch;
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);

    font-family: "Founders Grotesk";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%; /* 46.4px */
    letter-spacing: 0.04px;
}

.bodyText{
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);

    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
    align-self: stretch;
}

.cardButton{
    display: flex;
    padding: 13px 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 162px;
    height: 36px;

    border-radius: var(--Primary, 4px);
    border: 2px solid transparent;

    background-color: #FFF;

    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);

    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.boldText{
    font-weight: 700;
}

.buttonContainer{
    display: flex;
    width: 171px;
    height: 48px;
    /* padding: 13px 16px; */
    justify-content: center;
    align-items: center;
    background: rgb(240,150,189);
    background: radial-gradient(circle, rgba(240,150,189,1) 0%, rgba(148,197,233,0.9640231092436975) 97%);    /* gap: 8px; */
    border-radius: 8px;
    border: 2px solid #F5F3F6;
}