.container {
    display: flex;
    width: var(--size-size-50, 504px);
    flex-direction: column;
    align-items: flex-start;

    border-radius: var(--Container, 16px);
    background: var(--Base-Layers-layer-primary, #FFF);

    /* Shadow/Dropdown */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    height: fit-content;
    margin-top: 80px;

}

.upperContainer {
    display: flex;
    padding: 16px 16px 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-tertiary, #FAF9FB);

    border-top-left-radius: var(--Container, 16px);
    border-top-right-radius: var(--Container, 16px);

}


.cancel {
    width: var(--size-icon-size-icon-40, 24px);
    height: var(--size-icon-size-icon-40, 24px);

}

.formName {
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 2 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
}

.bottomContainer {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
}

.step {
    color: var(--Base-Text-grey-midemp, #66636D);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.stepBar {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.stepBarOne {
    width: 40px;
    height: var(--Secondary, 4px);

    border-radius: var(--Circle, 200px);
    background: var(--Base-Brand-violet, #A968DE);
}

.stepBarTwo {
    width: 40px;
    height: var(--Secondary, 4px);

    border-radius: var(--Circle, 200px);
    background: var(--Base-Components-Grey-high, #D2CDD7);


}

.stepBarThree {
    width: 40px;
    height: var(--Secondary, 4px);

    border-radius: var(--Circle, 200px);
    background: var(--Base-Components-Grey-high, #D2CDD7);

}

.practiceDetail {
    align-self: stretch;

    color: var(--Base-Text-grey-highemp, #211F26);

    /* Display/Display 3 */
    font-family: "Founders Grotesk Medium";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%;
    /* 46.4px */
    letter-spacing: 0.04px;
}

.field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.fieldName {
    color: var(--Base-Text-grey-midemp, #66636D);

    /* Label/Label 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
    letter-spacing: 0.084px;
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}

.fieldValue {
    color: var(--Base-Text-grey-midemp, #66636D);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
    background: var(--Base-Components-Violet-subtle, #FFF);


    /* display: flex; */
    padding: 13px 16px;
    /* align-items: center; */
    /* flex: 1 0 0;  */

    display: flex;
    align-items: center;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Brand-subtle, #F4E4FF);
}

.fieldValue:focus {
    outline: none;
}

.fieldValueDropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    display: flex;
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;

    background: var(--Base-Layers-layer-primary, #FFF);
    outline: none;

    border-radius: var(--Primary, 8px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 0px solid var(--Base-Stroke-Grey-low, #DCD8E0);
    border-right: 0;
}

.dropdown {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
}

.dropdownArrow {
    display: flex;
    padding: 13px;
    align-items: center;

    border-radius: var(--Primary, 8px);
    /* border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0); */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}

.fieldValueName {
    color: var(--Base-Text-grey-midemp, #66636D);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
    background: var(--Base-Components-Violet-subtle, #FCF8FF);


    /* display: flex; */
    padding: 13px 16px;
    /* align-items: center; */
    /* flex: 1 0 0;  */

    display: flex;
    align-items: center;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Brand-subtle, #F4E4FF);
}

.fieldValueName:focus {
    outline: none;
}

.continue {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);

    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
    cursor: pointer;
}

.continue:hover{
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Hover, linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), #F5F3F6);
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.worksiteId {
    display: flex;
    padding: 8px var(--space-space-40, 12px);
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-secondary, #FDFCFD);
}

.worksiteIdInfo {
    width: var(--size-icon-size-icon-40, 24px);
    height: var(--size-icon-size-icon-40, 24px);
}

.worksiteIdContent {
    color: var(--Base-Text-grey-midemp, #66636D);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
}

.worksiteIdValue {
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
}

.backAndContinue {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.back {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-mid, #D2CDD7);

    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;
}

.continueSmall {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 2 */
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.memberRole{
    display: flex;
height: var(--size-square-size-square-130, 48px);
padding: 4px;
align-items: center;
gap: 4px;
align-self: stretch;

border-radius: var(--Circle, 200px);
background: var(--Base-Components-Grey-subtle, #FAF9FB);
}

.roleAdmin{
    display: flex;
padding: 7px var(--space-space-40, 12px);
justify-content: center;
align-items: center;
gap: 8px;
flex: 1 0 0;
align-self: stretch;

border-radius: var(--Circle, 200px);
background: var(--Base-Layers-layer-primary, #FFF);

/* Component Active/Brand */
box-shadow: 0px 1px 8px 0px var(--Brand-Violet-Light-Alpha-Alpha04, rgba(169, 104, 222, 0.16));
}

.roleMember{
    display: flex;
padding: 7px var(--space-space-40, 12px);
justify-content: center;
align-items: center;
gap: 8px;
flex: 1 0 0;
align-self: stretch;

border-radius: var(--Circle, 200px);

}

