.errorContainer{
    display: flex;
    padding: 0px 5px;
    flex: 1 0 0;
    color: #9A3232;

    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
    letter-spacing: 0.084px;
}

.textContainer{
    display: flex;
    padding: 2px 3px;
    justify-content: center;
}