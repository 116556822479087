.container{
    min-width: 260px;
    min-height: 138px;
    width: auto;
    height: auto;
}

.upperContainer{
    display: flex;
    padding: var(--Container, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    border-bottom: 1px solid var(--Stroke-onBase-Subtle, #F5F3F6);
}

.lowerContainer{
    display: flex;
    padding: var(--Container, 16px);
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.nonData{
    height: 12px;
    align-self: stretch;
    width: 108px;

    border-radius: var(--Secondary, 4px);
    opacity: 0.24;
    background: var(--Foreground-Text-onBase-Low-Emphasis-rest, #7B7382);
}

.smallNoData{
    height: 12px;
    align-self: stretch;
    width: 80px;

    border-radius: var(--Secondary, 4px);
    opacity: 0.24;
    background: var(--Foreground-Text-onBase-Subtle-rest, #A49CAB);
}

.row{
    width: 100%;
    height: auto;
    display: flex;
}

.iconContainer{
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;

    border-radius: 133.333px;
    background: rgba(21, 18, 23, 0.08);
}

.addIcon{
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.mainRow{
    width: 100%;
    height: auto;
    display: flex;

    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.worksite{
    display: flex;
    padding: 0px var(--Container, 16px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
}

.worksiteHeading{
    display: flex;
    gap: 8px;
}

.worksiteHeadingText{
    width: 186px;
    height: 20px;

    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.verticalSeperator{
    display: flex;
    width: var(--Secondary, 4px);
    height: 12px;
    padding: 6px var(--Primary, 8px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.addressText{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;

    overflow: hidden;
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    text-overflow: ellipsis;
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}