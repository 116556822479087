.container{
    display: flex;
    align-items: center;
    gap: var(--Secondary, 4px);
}

.primaryTextContainer{
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Title/Title 2 */
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.typeContainer{
    display: flex;
    padding: var(--Tertiary, 2px) var(--Primary, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--Secondary, 4px);

    border-radius: var(--Secondary, 4px);

    /* Caption/Caption 3 */
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.balanced{
    background: var(--Base-Components-Green-low, #E5FAF2);
    color: var(--Base-Text-green-midemp, #2A8466);
}

.critical{
    background: var(--Base-Components-Green-low, #FEEFEB);
    color: var(--Base-Text-green-midemp, #EE9887);
}

.unbalanced{
    background: var(--Base-Components-Green-low, #FFF6BD);
    color: var(--Base-Text-green-midemp, #E4A944);
}