.non-active-tab{
    color: #2F4358;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Founders Grotesk";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
}

.active-tab{
    font-size: 22px;
    font-weight: 600;  
}
  
.tabContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
    cursor: pointer;
    width: auto;
    height: auto;
    padding: 5px;  
}