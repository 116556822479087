.container{
    display: flex;
    padding: 0px var(--Container, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;
}

.copyDataOuterContainer{
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    background : linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), var(--Muted-Old-950, #F5F3F6);;

    border-radius: var(--Circle, 200px);
    border: var(--Tertiary, 2px) solid var(--Interactive-Rest, #F5F3F6);

    cursor: pointer;
}

.copyDataOuterContainer:hover{
    border: 2px solid var(--Interactive-Hover, #F5F3F6);
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.primaryText{
    display: flex;
    align-items: center;
    gap : 6px;

    color: var(--Base-Text-grey-highemp, #211F26);
    text-align: right;
    font-family: "Founders Grotesk SemiBold";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.secondaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.copyDataInnerContainer{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    padding: 4px var(--Container, 14px);
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    background-color: #fff;
    border-radius: var(--Circle, 200px);
}

.primaryLogsInnerContainer{
    display: flex;
    padding: 16px;
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;
    justify-content: space-between;
    flex : 1 0 ;
}

.cardContainerClass{
    max-width: unset;
    flex: 1 1;
}

.innerContainer{
    display: flex;
    gap: var(--Container, 16px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.primaryLogsContainer{
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;

    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Surface-Container-primary, #FFF);
}

.betaIconContainer{
    display: flex;
    padding: var(--Tertiary, 2px) 12px;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Violet-dark, #C899EC);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);

    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.activityAnalysisContainer{
    display: flex;
    align-items: flex-start;
    align-self: stretch;
}

.headerContainer{
    display: flex;
    height: 72px;
    width: 100%;
    padding: 12px var(--Container, 16px);
    align-items: center;
    gap: var(--Primary, 8px);
    align-self: stretch;
    justify-content: space-between;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.summaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.optionsContainer{
    display: flex;
    width: auto;
    padding: var(--Secondary, 4px);
    align-items: center;
    gap: var(--Secondary, 4px);
    cursor: pointer;
    
}

.option{
    display: flex;
    padding: 7px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.active{
    border-radius: var(--Circle, 20px);
    background: var(--Base-Layers-layer-primary, #FFF);

    box-shadow: 0px 1px 8px 0px var(--Brand-Violet-Light-Alpha-Alpha04, rgba(169, 104, 222, 0.16));
    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.noDataIcon{
    width: 112px;
    height: 112px;
}

.noDataText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.noDataContainer{
    display: flex;
    gap: 8px;
    align-items: center;
}

.containerClass{

    display: flex;
    height: 52px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    
    background: var(--Base-Layers-layer-tertiary, #FAF9FB);

    color: var(--Base-Text-grey-midemp, #66636D) !important;
    font-family: "Founders Grotesk";
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.labelContainerClass{
    color: var(--Base-Text-grey-midemp, #66636D) !important;
    font-family: "Founders Grotesk Medium" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 140% !important; /* 19.6px */
    letter-spacing: 0.7px !important;
    text-transform: uppercase !important;

}

.trendAnalysisIndicator{
    display: flex;
    padding: 10px 24px;
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;
}

.fallbackContainer{
    display: flex;
    width: 1254px;
    height: 452px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: rgba(255, 255, 255, 0.40);
    backdrop-filter: blur(2px);
    position: absolute;
}

.fallbackIcon{
    width: 334.593px;
    height: 209.799px;
    flex-shrink: 0;
}

.balancedPlateContainer{
    width: 100%;
    background: var(--Base-Components-Grey-subtle, #FAF9FB);
    border-radius: 16px;
    height: auto
}

.selectTimeZoneClass{
    min-width: 100%;
    left: 0px;
    height: auto;
}

.activityAnalysisTableContainer{
    display: flex;
    padding: 16px;
    align-items: flex-start;
    align-self: stretch;
}