
.inputFieldRow{
    display: flex;
    flex-direction: row;
}

.errorClassName{
    display: flex;
    position: absolute;
    margin-left: 440px ;
    margin-bottom: 100px;
}

.passwordInputField{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* border-right: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED); */
}

.footerContainerClassname{
    gap: 8px;
}

.secondaryHeaderClassname{
    margin-top: 8px;
}

.enabledClassname{
    color: black;
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.resetPasswordContainer{
    min-height: 100vh;
    height: 100%;
}

.formContainer{
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    width: 504px;
    height: 400px;
    background-color: white;
    
    border-radius: var(--Container, 16px);
    background: var(--Background-Layer-Base-Primary-Rest, #FFF);
}

.passwordFormContainer{
    height: 420px;
}
