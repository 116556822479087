.container{
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.innerClass{
    display: flex;
    justify-content: center;
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.084px;
}

.boldFontContainer{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;
}

.componentContainer{
    padding: 0 !important;
}