.container{
    display: flex;
    width: 224px;
    flex-direction: column;
    align-items: flex-start;
}

.headerContainer{
    display: flex;
    width: 100%;
    height: 32px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.dateDataContainer{
    display: flex;
    flex-wrap: wrap;
}

.weeklyTextContainer{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.dateTextTypo{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.dateRightCornerClass{
    background: #854DC4 !important;
    border-radius: 0px 20px 20px 0px !important;
    font-size: 12px;
    color: var(--Inv-Text-grey-highemp, #EFEEF0);
}

.dateLeftCornerClass{
    background: #854DC4 !important;
    border-radius: 20px 0px 0px 20px !important;
    font-size: 12px;
    color: var(--Inv-Text-grey-highemp, #EFEEF0);
}

.dateCircularTypo{
    background: #854DC4 !important;
    border-radius: 20px !important;
    font-size: 12px;
    color: var(--Inv-Text-grey-highemp, #EFEEF0);
}

.disabledDateTypo{
    cursor: not-allowed;

    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
    text-decoration-line: strikethrough;
}

.inRangeDateClass{
    background: #A968DE !important;
    font-size: 12px;
    color: var(--Inv-Text-grey-highemp, #EFEEF0);

    border-radius: 0 !important;
}