.container{
    display: flex;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.optionsContainer{
    display: flex;
    padding: var(--Secondary, 4px);
    gap: var(--Secondary, 4px);
    cursor: pointer;
    
    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-tertiary, #FAF9FB);
}

.option{
    display: flex;
    padding: 7px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.active{
    border-radius: var(--Circle, 200px);
    background: var(--Base-Layers-layer-primary, #FFF);

    box-shadow: 0px 1px 8px 0px var(--Brand-Violet-Light-Alpha-Alpha04, rgba(169, 104, 222, 0.16));
    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.textBoxContainer{
    display: flex;
    padding: 13px 12px;
    align-items: center;
    gap: 6px;

    border-radius: var(--Primary, 8px);
}

.textBoxContainer:hover{
    border-radius: var(--Primary, 8px);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.textBoxActiveContainer{
    border-radius: var(--Primary, 8px);
    background: var(--Base-Layers-layer-primary, #FFF);

}

.icon{
    width: 20px;
    height: 20px;
}

.textBox{
    border: 1px solid transparent;
    width: 35px;
    background: inherit;

    overflow: hidden;

    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    text-overflow: ellipsis;
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    text-align: left;
}

.textBox:focus{
    border: 1px solid transparent;
    outline: none;
    width: 180px;

    color: var(--Base-Text-grey-midemp, #66636D);
    text-align: left;
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;    
}