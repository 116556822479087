.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.optionsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    width: auto;
    width: 100%;
    overflow: hidden;

    border-radius: var(--Container, 16px);
    background: var(--Surface-Container-primary, #FFF);
}