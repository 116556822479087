
@font-face{
  font-family: "Founders Grotesk";
  src: local("FoundersGrotesk"),
    url("./fonts/FoundersGrotesk-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Founders Grotesk Bold";
  src: local("FoundersGroteskBold"),
    url("./fonts/FoundersGrotesk-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Founders Grotesk Medium";
  src: local("FoundersGroteskMedium"),
    url("./fonts/FoundersGrotesk-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Founders Grotesk SemiBold";
  src: local("FoundersGroteskSemiBold"),
    url("./fonts/FoundersGrotesk-Semibold.otf") format("opentype");
}

*{
  font-family: "Founders Grotesk";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
