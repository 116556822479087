.container{
    width: 100%;
    height: 100%;
    position: fixed;
    padding-top: 100px;
    padding-left: 400px;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    width: 504px;
    height: auto;
    min-height: auto;
    overflow: visible;
}

.inputFieldRow{
    display: flex;
    flex-direction: row;
}

.errorClassName{
    display: flex;
    position: absolute;
    margin-left: 440px ;
    margin-bottom: 100px;
}

.passwordInputField{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* border-right: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED); */
}

.enabledClassname{
    color: black;
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.formContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;
    width: 100%;
    background-color: white;
    
    /* border-radius: var(--Container, 16px); */
    background: var(--Background-Layer-Base-Primary-Rest, #FFF);
}

.commonFieldContainer{
    width: 100%;
}