.container{
    display: flex;
    padding: 0 var(--Container, 16px);
    padding-bottom: 13px;
    align-items: flex-start;
    gap: var(--Primary, 8px);
    flex: 1 0 0;
    align-self: stretch;
}

.rightContainer{
    height: 100%;
    padding: 40px 0px;
    display: flex;
    align-items: center;
    align-content: center;
    gap: 16px var(--Container, 16px);
    flex: 1 0 0;
    flex-wrap: wrap;
}