.container{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.tabContainer{
    display: flex;
    padding: 7px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    align-self: stretch;
    cursor: pointer;
}

.iconContainer{
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.activeLabel{
    color: var(--Nav-Text-active-highemp, #C4F5EA);
}

.hovered{
    color: var(--Nav-Text-nav-highemp, #E5DDFE);
}

.iconText{
    color: var(--Nav-Text-nav-midemp, #C5B9F4);
    text-align: center;
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.iconInnerContainer{
    display: flex;
    width: 36px;
    height: 36px;
    padding: var(--Primary, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);
}

.activeIconContainer{
    border-radius: var(--Primary, 8px);
    background: var(--Nav-Brand-primary, #86EAC5);
}

.activeHovered{
    border-radius: var(--Primary, 8px);
    background: var(--Nav-Brand-secondary, #A8F5D9);
}

.iconHover{
    background-color: #F3F2F8;
    border-radius: var(--Primary, 8px);
    background: var(--Nav-Layers-nav-teriary, #403876);
}