.container{
    width: 100%;
    height: 500px;
    display: flex;
    min-width: 100%;
}

.chart-container {
    width: 100%;
    min-width: 100vw;
    height: 200px; /* Set the height as needed */
}

.highcharts-container{
    width: 100%;
}