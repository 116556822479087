.container{
    display: flex;
    flex-direction: column;
}

.averageText{
    color: #97A1AB;
    font-family: "Founders Grotesk";
    font-size: 7.327px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.212px; /* 166.667% */
    width: 100%;
    display: flex;
    justify-content: center;
}