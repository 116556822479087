.outerContainer{
    display: flex;
    padding: var(--Container, 16px) 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    background-color: #F1EFF3;
    align-self: stretch;
    height: calc(100vh - 200px)
}

.innerContainer{
    display: flex;
    padding: 40px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.icon{
    width: 160px;
    height: 160px;
}

.tableContainer{
    height: auto;
    overflow: hidden;
    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
}

.inviteButton{
    width: 146px;
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.224px;
}

.primaryText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.secondaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.rowColumnHeader{
    background: var(--Background-Layer-Base-Secondary-Rest, #FAF9FB);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    font-size: 14px;
    text-transform: uppercase;

    padding: 14px 0px;
    border-bottom: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
}