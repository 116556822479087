.container{
    display: flex;
    gap: 15px;
}

.addIcon{
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    cursor: pointer;
}

.actualImg{
    width: 100%;
    height: 100%;
}

.photoContainer{
    display: flex;
    width: 72px;
    height: 72px;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: var(--Circle, 200px);
    border: 1px dashed var(--Stroke-onBase-Primary-hover, #E1DDE3);
}

.uploadContainer{
    display: flex;
    align-items: flex-start;
    gap: var(--Container, 16px);

}

.uploadText{

    color: var(--Foreground-Text-Interactive-Rest, #8427CE);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    border-bottom: 1px dashed var(--Base-Stroke-Brand-mid, #DAB5F5);
    cursor: pointer;
}

.removePhoto{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Secondary, 4px);
    border-bottom: 1px dashed var(--Base-Stroke-Danger-mid, #FDCABE);

    color: var(--Base-Text-red-midemp, #AE4B37);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    cursor: pointer;
}

.fileSelectionPopupContainer{
    display: flex;
    width: 190px;
    padding: var(--Tertiary, 2px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Tertiary, 2px);
    border-radius: var(--Secondary, 4px);
    background: var(--Background-Component-onLayer-Rest, #FFF);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.rightContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    flex: 1 0 0;
}

.textContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.headingText{
    color: var(--Foreground-Text-onBase-Low-Emphasis-rest, #7B7382);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.contextText{
    color: var(--Foreground-Text-onBase-Low-Emphasis-rest, #7B7382);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.popupOptions{
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    cursor: pointer;

    border-radius: var(--Tertiary, 2px);
    background: var(--Background-Component-onLayer-Rest, #FFF);

    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.popupContainer{
    display: flex;
    width: 190px;
    padding: var(--Tertiary, 2px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Tertiary, 2px);
    position: absolute;
    margin-top: 120px;
    cursor: pointer;

    border-radius: var(--Secondary, 4px);
    background: var(--Background-Component-onLayer-Rest, #FFF);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.fileSelect{
    background-color: #FFF;
    position: absolute;
    border: 1ps solid transparent;
    outline: none;
    cursor: pointer;
}

.profileImage{
    width: 73px;
    height: 73px;
}