.container{
    width: 100%;
    display: flex;
    padding: 14px var(--Container, 16px);
    gap: var(--Tertiary, 2px);
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.leftContainer{
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.primaryContent{
    display: flex;
    justify-content: center;
    gap: var(--Primary, 8px);
    max-height: 26px;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.secondaryContent{
    display: flex;
    gap: var(--Primary, 8px);

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.verticalDivider{
    width: 1px;
    height: 12px;

    background: var(--Base-Stroke-Grey-mid, #D2CDD7);
}

.headerIcon{
    width: 16px;
    height: 16px;
}
