
.leftCardHeader{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.leftCardHeaderText{
    display: flex;

    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}


.progressStepperContainer{
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px;
    padding-left:0px ;
}

.nonActiveProgressStepper{
    width: 40px;
    height: var(--Secondary, 4px);

    border-radius: var(--Circle, 200px);
    background: var(--Background-Component-Rest, #CCC8D0);
}

.progressStepper{
    width: 40px;
    height: var(--Secondary, 4px);

    border-radius: var(--Circle, 200px);
    background: var(--Background-Component-Selected, #AA69DE);
}