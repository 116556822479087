
.modalContainer{
    min-width: 300px;
    min-height: 300px;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    background-color: rgba(255, 255, 255, 1);
    border-radius: 20px;
    border: 2px solid #f0dcdc;
    overflow: hidden;
    z-index: 10;
    flex-shrink: 0;
    transform: translate(0,0);
    overflow: hidden;
    justify-content: space-between;
}

.headerContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.closeIcon{
    cursor: pointer;
}