.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-space-70, 24px);
    align-self: stretch;

    display: flex;
    padding: var(--space-space-110, 40px);
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

}

.paginationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-space-70, 24px);
    align-self: stretch;
    margin-left: auto;
    margin-right: auto;
}

.arrow {
    display: flex;
    width: var(--size-icon-size-icon-70, 32px);
    height: var(--size-icon-size-icon-70, 32px);
    padding: 2px 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: var(--Circle, 200px);
    background: var(--Base-Layers-layer-primary, #FFF);

    cursor: pointer;
}

.pageNumber {
    display: flex;
    width: var(--size-icon-size-icon-70, 32px);
    height: var(--size-icon-size-icon-70, 32px);
    padding: 2px 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Brand-violet, #A968DE);
    background: var(--Base-Layers-layer-primary, #FFF);
}