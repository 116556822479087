.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.paginationContainer{
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    bottom: 0;
    padding: 10px 0px;
}

.activeControl{
    color: var(--Base-Text-brand-primary, #854DC4);
    border: 1px solid var(--Base-Brand-violet, #A968DE);
}

.iconClass{
    width: var(--Container, 16px);
    height: var(--Container, 16px);
    flex-shrink: 0;
}

.disabledIcon{
    cursor: not-allowed;
    background-color: #fff;
}

.disabledIcon:hover{
    cursor: not-allowed;
    background-color: #fff;
}