.container{
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;

    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Surface-Container-primary, #FFF);
}

.header{
    display: flex;
    height: auto;
    padding: 12px var(--Container, 16px);
    align-items: center;
    gap: var(--Primary, 8px);
    align-self: stretch;
    width: 100%;

    background: var(--Base-Components-Grey-subtle, #FAF9FB);
}

.body{
    display: flex;
    /* padding: 0px var(--Container, 16px) var(--Container, 16px) var(--Container, 16px); */
    padding: 16px;
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;
}