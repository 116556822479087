.container{
    display: flex;
    height: 100%;
    flex-direction: column;
    padding-top:16px ;
    align-items: center;
    gap: var(--Container, 16px);
    flex-shrink: 0;
    align-self: stretch;
}

.emptyIcon{
    width: 120px;
    height: 120px;
    flex-shrink: 0;
}

.textContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.primaryText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.secondaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}