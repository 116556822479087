.container{
    font-size: medium;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0 0 12px;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}