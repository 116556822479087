.container{
    width: 100vw;
    min-height: 100vh;
    height: auto;
    background-color: rgba(255, 255, 255, 0.8);
    position: fixed;
    display: flex;
    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer{
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content:flex-start;
    height: auto;

    border-radius: var(--Container, 16px);
    background: var(--Base-Layers-layer-primary, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}

.enabledClassname{
    color: black;
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.formContainer{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px var(--Container, 16px);
    align-self: stretch;
    width: auto;
    min-height: 456px;
    height: auto;
    background-color: white;
    
    border-radius: var(--Primary, 8px);
}

.footerContainerClass{
    display: flex;
    padding: 13px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: auto;
    align-self: stretch;
}