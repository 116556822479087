.container{
    display: flex;
    width: 100%;
    height: 172px;
    padding: 0px 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Container, 16px);
    align-self: stretch;

    background-color: #fff;
}

.mainContentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Secondary, 4px);
    align-self: stretch;
}

.mainLabel{
    align-self: stretch;
    color: var(--Base-Text-grey-highemp, #211F26);
    text-align: center;
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.mainContent{
    align-self: stretch;
    color: var(--Base-Text-grey-midemp, #66636D);
    text-align: center;
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.mainIcon{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}