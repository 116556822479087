.container{
    display: flex;
    width: 100%;
    flex-basis: 100%;
    flex-wrap: nowrap;
    background-color: #ffffff;
    
    cursor: pointer;
}

.checkbox{
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.innerClass{
    align-items: center;
}