.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    min-width: 800px;
    max-width: 800px;
}

.fallback{
    width: auto !important;
    color: var(--Base-Text-grey-highemp, #211F26) !important;
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.redText{
    color: var(--Base-Brand-green, #d62404) !important;
    text-align: center;
    
    /* Title/Title 2 */
    font-family: "Founders Grotesk";
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 128% !important; /* 25.6px */
    letter-spacing: 0.08px;
}

.yellowText{
    color: var(--Base-Brand-yellow-hover, #CA9416) !important;
    text-align: center;
    
    /* Title/Title 2 */
    font-family: "Founders Grotesk";
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 128% !important; /* 25.6px */
    letter-spacing: 0.08px;

}

.greenText{
color: var(--Base-Brand-green, #04CD83) !important;
text-align: center;

/* Title/Title 2 */
font-family: "Founders Grotesk";
font-size: 20px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 128% !important; /* 25.6px */
letter-spacing: 0.08px;
}