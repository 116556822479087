.container{

}

.dateLabel{
    display: flex;
    padding: 6px 4px;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
    background: var(--Base-Layers-layer-primary, #FFF);

    cursor: pointer;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.dateLabel:hover{
    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
    background: var(--Base-Layers-layer-primary, #FFF);

    color: var(--Base-Text-brand-primary, #854DC4);
}

.arrowIcon{
    width: 24px;
    height: 24px;
}

.dateContentContainer{
    display: flex;
    height: 28px;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.iconContainer{
    display: flex;
    padding: 2px;
    align-items: center;
    gap: 8px;
}

.iconContainer:hover{
    border-radius: var(--Secondary, 4px);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.dateContentContainer:hover{
    border-radius: var(--Secondary, 4px);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
}

.nonActiveArrow{
    cursor: not-allowed;
}

.nonActiveArrow:hover{
    background-color: #fff;
}