.container{
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 8px;
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

    background-color: #fff;
    position: absolute;
    right:20px;

    overflow: hidden;
}
