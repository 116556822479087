.container{
    display: flex;
    padding: 9px 12px;
    align-items: center;
    gap: 4px;
}

.selectDropdown{
    width: 115px !important;
    display: flex !important;
    padding: 6px var(--Secondary, 4px) !important;
    align-items: center !important;
    gap: var(--Tertiary, 2px) !important;
    border: 2px solid transparent !important;
    background-color: inherit !important;

    color: var(--Base-Text-grey-midemp, #66636D) !important;
    font-family: "Founders Grotesk" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 140% !important;
    letter-spacing: 0.064px !important;

    cursor: pointer !important;
    box-shadow: none !important;
}

.selectDropdown:hover{
    box-shadow: none !important;
    border: 2px solid transparent !important;
}

.selectDropdown:active{
    box-shadow: none !important;
    border: 2px solid transparent !important;
}