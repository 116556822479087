.container{
    display: flex;
    align-items: center;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: var(--Background-Component-onLayer-Rest, #FFF);
}

.prefixContainer{
    display: flex;
    padding: 13px 16px;
    align-items: center;

    border-right: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: var(--Background-Component-onLayer-Disabled, #EBE8ED);
}

.inputBox{
    display: flex;
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;

    background: var(--Background-Component-onLayer-Rest, #FFF);
    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
}