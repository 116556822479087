.container{
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.concentTypography{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    flex: 1 0 0;
}

.checkBox{
    border-radius: var(--Tertiary, 2px);
    border: var(--Tertiary, 2px) solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

    width: 18px;
    height: 18px;
    flex-shrink: 0;
}