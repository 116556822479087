.container{
    width: 100%;
    display: flex;
    padding: var(--Container, 16px) var(--Container, 16px) var(--Container, 16px) 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-tertiary, #FAF9FB);

    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}