.container{
    height: 100%;
    display: flex;
    align-items: center;
    gap: var(--Primary, 8px);
    flex: 1 0 0;
    align-self: stretch;
}

.icon{
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Circle, 200px);
    background: var(--Base-Components-Green-low, #E5FAF2);
}

.dateContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.dateText{
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.pendingStatus{
    border-radius: var(--Circle, 200px);
    background: var(--Base-Components-Green-low, #E5FAF2);

    color: var(--Base-Text-green-midemp, #2A8466);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.unsentStatus{
    border-radius: var(--Circle, 200px);
    background: var(--Base-Components-Red-low, #FEEFEB);
    
    color: var(--Base-Text-red-midemp, #AE4B37);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}