.container{
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    background-color: #fff;
    border-left: 1px solid rgba(21, 18, 23, 0.16);
    
}

.containerBackground{
    /* width: 100%; */
    /* height: 100%; */
    /* position: absolute; */
    /* z-index: 10; */
    /* background: rgba(21, 18, 23, 0.16); */
}