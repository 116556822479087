.rightContainer{
    width: auto;
    /* width: 240px; */
    display: flex;
    padding: 0px var(--Container, 16px);
    justify-content: space-between;
    align-items: center;
}

.bellIconContainer{
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.bellIcon{
    width: 24px;
    height: 24px;

    cursor: pointer;
}

.frame{
    width: 1px;
    height: 40px;
    background: var(--Base-Stroke-Grey-Low, #EAE7EC);
}

.optionClassName{
    border-top: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}