.container{
    display: flex;
    background: var(--Nav-Layers-nav-primary, #29255B);
    padding: 0%;
    margin: 0%;
    height: calc(100%);
    min-width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 11;
}

.mainIcon{
    width: 21.6px;
    height: 24px;
    flex-shrink: 0;
}

.mainLogo{
    width: 80px;
    min-height: 64px;
    max-height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.mainSideBar{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80px;

    padding: 16px 0px;
    flex-direction: column;
    gap: 4px;
}

.childComponents{
    width:100% ;
    margin-top: 64px;
    margin-left: 80px;
}

.sideBarOuterContainer{
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 1024px;
}