.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Secondary, 4px);
    flex: 1 0 0;
    align-self: stretch;
}

.primaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
    text-transform: none !important;
}

.alertContainer{
    display: inline-flex;
    align-items: center;
    gap: 4px;

    text-transform: none;
}

.alertContent{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.alertMetricIcon{
    width: 16px;
    height: 16px;
}

.greenContent{
    color: var(--Base-Text-green-midemp, #2A8466);
}

.redContent{
    color: var(--Base-Text-red-midemp, #AE4B37);
}

.noData{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}