.baseContainer{
    height: auto;
    width: auto;
    display: flex;
    padding: 4px 24px;
    gap : 8px;
}

.contentContainer{
    height: auto;
    width: auto;
    display: flex;
    gap : 24px;
}

.profilePhoto{
    display: flex;
    width: 46px;
    height: 46px;
    padding: 0px 1px;
    justify-content: center;
    align-items: center;
    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.outerButtonContainer{
    padding: 2px;
    color: black;
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.innerButtonContainer{
    display: flex;
    padding: 13px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: var(--Primary, 8px);
    border: 2px solid var(--Interactive-Rest, #F5F3F6);
    background-color: #fff;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.064px;
    cursor: pointer;
}

.profileIconContainer{
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 8px;

    padding-right: 0px;

    justify-content: center;
}