.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    /* overflow: scroll; */
}

.tabContainer{
    display: flex;
    width: 100%;
    height: 100%;
    top: 30px;
    flex-direction: row;
    gap:42px;  
}

.manage-action-plan-div {
    top: 30px;
    right: 45px;
    width: 200px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1.5px solid #2f4358;
    background: linear-gradient(
        225deg,
        rgba(87, 239, 195, 0.5) 0%,
        rgba(193, 127, 242, 0.5) 51.26%,
        rgba(255, 178, 0, 0.5) 100%
      ),
      #fff;
    box-shadow: 0px 5px 20px 0px #ddc3f8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.manage-action-plan-span {
    color: #2f4358;
    text-align: center;
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.secondaryHeader{
    display: flex;
    justify-content: space-between;
    padding: 25px 50px;
}