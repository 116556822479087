.container{
    width: auto;    
}

.tabsContainer{
    width: 100%;
    display: flex;
    padding: 10px var(--Container, 16px) 0px var(--Container, 16px);
    align-items: flex-start;
    align-self: stretch;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.tabOption{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.activeTab{
    border-bottom: 2px solid var(--Base-Stroke-Brand-mid, #DAB5F5);
    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}