.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background-color: #fff;

    height: auto;
    width: 100%;
    align-items: center ;
    cursor: pointer;
    border-radius: var(--Container, 16px);

    overflow: hidden;
}

.container:hover{
    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Components-Grey-subtle, #FAF9FB);
    box-shadow: 0px 8px 16px 0px var(--Muted-Alpha-100, rgba(21, 18, 23, 0.08));
}