.container{
    width: 100%;
    overflow: hidden;
    border-radius: var(--Container, 16px);
    background: var(--Base-Layers-layer-primary, #FFF);  
}


.columnHeaderTypo{
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 2px;
    flex: 1 0 0;
    align-self: stretch;
    justify-content: flex-start;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}

.logTimeContainer{
    display: flex;
    min-width: 160px;
}

.mealColumnContainer{
    display: flex;
    min-width: 494px;
}

.innerClassName{
    padding-left: 0 !important;
}

.columnHeaderRowProps{
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Components-Grey-subtle, #FAF9FB);   
}

.noDataContainerClass{
    width: auto;
    min-height: 215px;
    display: flex;
    justify-content: center;
}

.secondaryTextClass{
    flex: none;
}

.rowContainer{
    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.rowContainer:hover{
    background: var(--Base-Components-Violet-subtle, #FCF8FF) !important;
}

.topHeaderClass{
    border-bottom: none;
}