.container{
    display: inline-flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;

    border-radius: var(--Tertiary, 2px);
    background: var(--Inv-Components-Grey-low, #232225);

    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}

.content{
    color: var(--Inv-Text-grey-highemp, #EFEEF0);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;    
}