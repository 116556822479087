.containers{
    width: 100%;
    min-height: 100%;
}

.rightContainer{
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex: 1 0 0;
    align-self: stretch;

    background: var(--Stroke-onBase-Primary-focused, #D0A9EF);
}

.leftCardContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
}

.leftCardHeader{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.leftCardHeaderText{
    display: flex;

    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.progressStepperContainer{
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px;
    padding-left:0px ;
}

.nonActiveProgressStepper{
    width: 40px;
    height: var(--Secondary, 4px);

    border-radius: var(--Circle, 200px);
    background: var(--Background-Component-Rest, #CCC8D0);
}

.progressStepper{
    width: 40px;
    height: var(--Secondary, 4px);

    border-radius: var(--Circle, 200px);
    background: var(--Background-Component-Selected, #AA69DE);
}