.container{
    display: flex;
    padding: 0px 32px 32px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%; /* 46.4px */
    letter-spacing: 0.04px;
}