.container{
    display: flex;
    width: 400px;
    padding: var(--Container, 16px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Primary, 8px);
}

.bodyContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
}

.bodyItem{
    display: flex;
    width: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Primary, 8px);
}

.bodyContentLabel{
    align-self: stretch;
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.bodyContent{
    align-self: stretch;
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.bodyFooter{
    display: flex;
    width: 400px;
    padding: var(--Container, 16px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Primary, 8px);
}

.bodyFooterLabel{
    align-self: stretch;
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.bodyFooterButtonContainer{
    display: flex;
    align-items: center;
    gap: 12px;
}

.bodyFooterButtons{
    display: flex;
    height: 32px;
    padding: 6px var(--Container, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
}