.container{
    display: flex;
    width: 528px;
    flex-direction: column;
    align-items: flex-start;

    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    background: var(--Base-Layers-layer-primary, #FFF);

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}