.container{
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--Container, 16px) 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.leftContainer{
    display: flex;
    flex-direction: column;
}

.mainText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.secondaryText{
    display: flex;
    align-items: flex-end;
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.icon{
    width: 20px;
    height: 20px;
}

.inviteButton{
    width: 150px;
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.224px;
}