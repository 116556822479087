.container{
    display: flex;
    padding: var(--Primary, 8px);
    justify-content: space-between;
    align-items: center;
    gap: var(--Primary, 8px);
    align-self: stretch;

    border-radius: var(--Tertiary, 2px);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.primaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.secondaryText{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}