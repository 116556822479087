.container{
    display: flex;
    padding: var(--Container, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    flex: 1 0 0;
    align-self: stretch;
    overflow: scroll;
}

.heading{
    align-self: stretch;
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.content{
    align-self: stretch;
    color: var(--Foreground-Text-onBase-Medium-Emphasis-rest, #524B58);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.contentContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
}