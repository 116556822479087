.container{
    display: flex;
    align-items: center;
    align-self: stretch;
    width: 100%;

}

.prefixContainer{
    display: flex;
    padding: 13px 16px;
    align-items: center;

    border-right: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED);
    background: var(--Background-Component-onLayer-Rest, #FFF);
    height: 50px;
    max-width: 75px;
}

.inputField{
    display: flex;
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;
    width: 100%;
    background: var(--Background-Component-onLayer-Rest, #FFF);
}