.container{
    width: 100%;
    height: auto;

    display: flex;
    padding: 0px var(--Container, 16px);
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px var(--Container, 16px);
    flex: 1 0 0;
    align-self: stretch;
    flex-wrap: wrap;

    flex-direction: column;
    background-color: #EAE7EC;
}

.betaIconContainer{
    display: flex;
    padding: var(--Tertiary, 2px) 12px;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);

    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Components-Violet-dark, #C899EC);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);

    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.mainLabel{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%;
    letter-spacing: 0.08px;

}

.balancedPlateLabelContainer{
    display: flex;
    gap: 8px;
}

.daysText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.statisticsOuterContainer{
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    max-height: 414px;

    flex: 1 1 ;
    flex-wrap: wrap;
}

.imageClass{
    width: 16px;
    height: 16px;
}

.rightBlockContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    max-width: 354px;
    gap: 6px;
    height: 100%;
}

.recentTrendHeaderContainer{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;

    color: var(--Base-Text-grey-midemp, #66636D);
    text-overflow: ellipsis;

    height: 72px;

    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.statisticsContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px var(--Container, 16px);
    flex-wrap: wrap;

    border-radius: var(--Primary, 8px) var(--Primary, 8px) 0px 0px;
}

.primaryOverTextMain{
    color: #000;
    text-align: right;
    font-family: "Founders Grotesk Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.topHeader{
    width: 100%;
    display: flex;
    padding: 16px 32px var(--Primary, 8px) 32px;
    align-items: center;
    gap: var(--Secondary, 16px);
    align-self: stretch;
    position: sticky;
    top: 121px;
    z-index: 110;
    background-color: #EAE7EC;
}

.recentActivity{
    display: flex;
    height: 456px;
    max-width: 408px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;

    border-radius: var(--Container, 16px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.icon{
    width: 270px;
    height: 180px;
    flex-shrink: 0;
}

.betaTagContainer{
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Secondary, 4px);
    background: var(--Base-Components-Green-low, #E5FAF2);

    color: var(--Base-Text-green-midemp, #2A8466);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.durationCalender{
    display: flex;
    padding: 9px 12px;
    align-items: center;
    gap: 4px;

    border-radius: var(--Primary, 8px);
    background: var(--Base-Layers-layer-primary, #FFF);

    cursor: context-menu;
}

.calenderIcon{
    width: 16px;
    height: 16px;
}

.selectDropdown{
    width: 115px !important;
    display: flex !important;
    padding: 6px var(--Secondary, 4px) !important;
    align-items: center !important;
    gap: var(--Tertiary, 2px) !important;
    border: 2px solid transparent !important;
    background-color: inherit !important;

    color: var(--Base-Text-grey-midemp, #66636D) !important;
    font-family: "Founders Grotesk" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 140% !important;
    letter-spacing: 0.064px !important;

    cursor: pointer !important;
    box-shadow: none !important;
}

.selectDropdown:hover{
    box-shadow: none !important;
    border: 2px solid transparent !important;
}

.selectDropdown:active{
    box-shadow: none !important;
    border: 2px solid transparent !important;
}

.recentActivityContainer{
    max-width: 100% !important;
}