.container{
    display: flex;
    align-items: center;
    gap: var(--Primary, 8px);
    align-self: stretch;
}

.checkBox{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 3px;
    justify-content: center;
    align-items: center;
}

.concentTypography{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}