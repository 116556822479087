.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.outerContainer{
  width: 100%;
  height: 100%;
  padding: 16px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Container, 16px);
  align-self: stretch;
  background-color: #EAE7EC;;
}

.mainContainer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Container, 16px);
  align-self: stretch;
  background-color: #EAE7EC;;
}

.summaryContainerClass{
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.comparisonBodyClass{
  padding: 0%;
}

.cardContainer{
  width: 100%;
  overflow: hidden;
  border-radius: var(--Container, 16px);
  background: var(--Base-Layers-layer-primary, #FFF);
}

.logsText{
  gap:6px;
  display: flex;
  color: var(--Base-Text-grey-midemp, #66636D);

  /* Body/Body 2 */
  font-family: "Founders Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.064px;

  display: flex;
  height: 56px;
  padding: 12px var(--Container, 16px);
  align-items: center;
  gap: var(--Primary, 8px);
  align-self: stretch;
  background: var(--Base-Components-Grey-subtle, #FAF9FB);

}

.weeklyFilter{
  cursor: pointer;
  margin: 10px;
}

.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
}

.summaryHeaderClassname{
  color: var(--Base-Text-grey-midemp, #66636D);

  font-family: "Founders Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.064px;

  display: flex;
  align-items: center;
  gap: var(--Primary, 8px);
  align-self: stretch;
  background: var(--Base-Components-Grey-subtle, #FAF9FB);
}

.betaIconContainer{
  display: flex;
  padding: var(--Tertiary, 2px) 12px;
  justify-content: center;
  align-items: center;
  gap: var(--Primary, 8px);
  width: auto;

  border-radius: var(--Secondary, 4px);
  border: 1px solid var(--Base-Components-Violet-dark, #C899EC);
  background: var(--Base-Components-Violet-subtle, #FCF8FF);

  color: var(--Base-Text-brand-primary, #854DC4);
  font-family: "Founders Grotesk";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 148%; /* 17.76px */
  letter-spacing: 0.072px;
}