.container{
    display: flex;
    width: auto;
    box-shadow: 5px;
    position: fixed;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
    z-index: 120;
    left: 0;
}