.container{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.navbar{
    display: flex;
    width: 1286px;
    height: 68px;
    justify-content: space-between;
    align-items: center;
    padding: auto 10px;
    padding-left: 80px;
    box-shadow: 0px 4px 4px 0px rgba(58, 51, 94, 0.20);

    border-bottom: 1px solid var(--Base-Stroke-Grey-Low, #EAE7EC);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.sideBarClass{
    position: relative;
    z-index: 0;
}

.sideBarChildComponents{
    margin-top: 0px;
    margin-left: 0px;
}