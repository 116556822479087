.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    gap: var(--Primary, 8px);
}

.mainText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.secondaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}