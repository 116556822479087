.container{
    display: flex;
    min-width: 120px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Tertiary, 2px);
    flex: 1 0 0;
}

.primaryText{
    display: flex;
    gap: 2px;
    align-items: flex-end;

    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.secondaryText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.noData{
    width: 100%;
    height: 100%;
    align-items: center;

    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}