.container{
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--Container, 16px) 24px 24px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
    align-self: stretch;
}

.textContainer{
    display: flex;
    padding: 10px var(--Container, 16px);
    align-items: center;
    gap: var(--Secondary, 4px);
    align-self: stretch;

    border-radius: var(--Secondary, 4px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    background: var(--Base-Components-Grey-subtle, #FAF9FB);

    color: var(--icon-text-highemp, #1F1C22);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.redirectText{
    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}