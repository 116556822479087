.container{
    min-height: 100%;
    height: auto;
    width: 966px;
    display: grid;
    grid-template-columns: 55% 45%;
    border-radius: var(--Container, 16px);
    background: var(--Background-Layer-Base-Primary-Rest, #FFF);
}

.phoneOtpModal{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalInnerContainer{
    max-width: 524px !important;
    min-height: auto !important;
}

.outerContainer{
    width: 100%;
}

.spinnerContainer{
    top:0;
}

.formHeaderProps{
    align-self: stretch;
    color: var(--Foreground-Text-onBase-High-Emphasis-rest, #1A171C);
    font-family: "Founders Grotesk";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 116%; /* 46.4px */
    letter-spacing: 0.04px;
}

.formContainer{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.formBodyContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Container, 16px);
}

.enabledClassname{
    color: black;
    border-radius: var(--Primary, 8px);
    background: var(--Interactive-Rest, linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%), #F5F3F6);
}

.mainContainer{
    width: 100%;
    min-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upperCard{
    width: 966px;
    height: 10px;
    padding: 2px 2px 2px 10px;

    color: var(--Background-Component-Selected-Hover, #9745D9);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
    margin-bottom: 15px;

    cursor: pointer;
}

.customBasicDropdown{
    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
}

.innerPrimaryContainer{
    min-width: 100%;
}

.selectTimeZoneClass{
    min-width: 100%;
    left: 0px;
    height: auto;
}

.inputFieldRow{
    display: flex;
    flex-direction: row;
}

.errorClassName{
    display: flex;
    position: absolute;
    margin-left: 440px ;
    margin-bottom: 100px;
}

.commonFieldContainer{
    width: 100%;
}

.passwordInputField{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* border-right: 1px solid var(--Stroke-onBase-Primary-rest, #EBE8ED); */
}

.multiSelectDropDownContainer{
    max-height: 200px;
    overflow-y: scroll;
}

.disabledEmailBox{
    border-radius: var(--Primary, 8px) !important;
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC) !important;
    background: var(--Base-Components-Grey-mid, #EAE7EC) !important;

    color: var(--Base-Text-grey-lowemp, #C0BAC7) !important;
    font-family: "Founders Grotesk Medium" !important;
}

.spinnerContainer{
    top:0 !important
}