.headerContainer{
    color: var(--Foreground-Text-onInverted-High-Emphasis-rest, #FAF9FB);

    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.textContainer{
    display: flex;
    flex-direction: column;
}

.textRow{
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.textContent{
    flex: 1 0 0;
    color: var(--Foreground-Text-onInverted-Medium-Emphasis-rest, #CCC8D0);

    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.container{
    width: 100%;
    border-radius: var(--Primary, 8px);
    background: var(--Background-Layer-Inverted-Secondary-Rest, #29252D);
    display: flex;
    flex-direction: column;
}