.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex: 1 0 0;
}

.mainLabel{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}