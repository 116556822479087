.container{
    display: flex;
    padding: 14px var(--Container, 16px);
    align-items: center;
    justify-content: space-between;
    gap: var(--Tertiary, 2px);
    align-self: stretch;

    height: 72px;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
    
    width: 100%;
}

.primaryTextContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);
}

.headerTypography{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.icon{
    display: flex;
    align-items: flex-start;
    gap: var(--Primary, 8px);

    width: 16px;
    height: 16px;
}