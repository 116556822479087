.container{
    display: flex;
    height: 72px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: 14px 16px;
    gap: 2px;

    border-bottom: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);
}

.leftContainer{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%; /* 25.6px */
    letter-spacing: 0.08px;
}

.rightContainer{
    display: flex;
    align-items: center;
    align-self: stretch;
}