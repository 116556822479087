.container{
    font-size: medium;
    height: 100%;
    display: flex;
    align-items: center;

    color: var(--Base-Text-grey-midemp, #66636D);

    /* Body/Body 2 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.activeRow{
    color: var(--Base-Text-brand-primary, #854DC4);
}