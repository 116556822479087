.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;   
}

.inputContainer{
    display: flex;
    align-items: center;
    align-self: stretch;

    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Brand-subtle, #F4E4FF);

    display: flex;
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;
}

.filteredListContainer{
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: space-between;

    border-radius: var(--Tertiary, 2px);
    background: var(--Base-Layers-layer-primary, #FFF);
}

.listOptionLeftContainer{
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.listOptionRightContainer{
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.listMainTextContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
}

.listSecondaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.listPrimaryText{
    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.fieldLabel{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    padding: 0px 8px;
}

.buttonContainer{
    padding: 2px;
    background: linear-gradient(264deg, rgba(87, 239, 195, 0.50) 0%, rgba(193, 127, 242, 0.50) 78.31%, rgba(255, 178, 0, 0.50) 100%) !important;
    border-radius: var(--Primary, 8px);

    cursor: pointer;
}

.alreadyAddedTypo{
    color: #000;
    font-family: "Founders Grotesk Medium";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.noItemContentContainer{
    display: flex;
    width: 243px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.noItemPrimaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.noItemListContainer{
    display: flex;
    padding: var(--space-space-40, 12px) 16px var(--space-space-40, 12px) var(--space-space-40, 12px);
    align-items: center;
    gap: var(--space-space-40, 12px);
    align-self: stretch;

    background: var(--Base-Layers-layer-primary, #FFF);
}

.noItemSecondaryText{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 148%; /* 17.76px */
    letter-spacing: 0.072px;
}

.notFoundIcon{
    width: 108px;
    height: 108px;
}

.button{
    display: flex;
    width: 88px;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: var(--Primary, 8px);
    background-color: #fff;

    color: var(--Base-Text-grey-highemp, #211F26);
    font-family: "Founders Grotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;
}

.initialsContainer{
    display: flex;
    width: var(--size-square-size-square-130, 48px);
    height: var(--size-square-size-square-130, 48px);
    padding: 13px 0px;
    justify-content: center;
    align-items: center;
    border-radius: var(--Circle, 200px);
    border: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

    color: var(--Base-Text-grey-highemp, #211F26);
    text-align: center;

    font-family: "Founders Grotesk Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.activeListOptionContainer{
    background-color: #AE4B37 !important;
}

.activeListOption{
    border-radius: var(--Primary, 8px);

    color: var(--Base-Text-red-midemp, #AE4B37);
    font-family: "Founders Grotesk Medium";
}

.listContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;


    max-height: 230px;
    overflow-y: scroll;
}