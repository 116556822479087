.container{
    display: flex;
    height: auto;
    padding: 12px 12px 12px 0px;
    width: 100%;
    gap: 4px;
    justify-content: space-between;

    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.sourceTabType{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.leftContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Primary, 8px);
}

.primaryIcon{
    width: var(--Container, 16px);
    height: var(--Container, 16px);
}

.dataIcon{
    color: var(--Base-Text-grey-highemp, #211F26);
}

.modalContainer{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 131;
    padding-top: 60px;
    overflow: scroll;
}

.modalMainContainer{
    max-width: 800px;
}  

.container:hover{
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
    cursor: pointer;
}

.noDataContainer{
    color: var(--Base-Text-grey-lowemp, #C0BAC7);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;}

.aboveRange{
    background: var(--Base-Components-Yellow-subtle, #FFFBE0);
}

.belowRange{
    background: var(--Base-Components-Red-subtle, #FFF6F5);
}

.iconStyles{
    width: var(--Container, 16px);
    height: var(--Container, 16px);
}