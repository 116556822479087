.container{
    display: flex;
    align-items: center;
    gap: 24px;
    width: auto;
}

.optionContainer{
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: var(--Circle, 200px);
    background: var(--Base-Components-Grey-low, #F1EFF3);
}