.searchContainer {
    display: flex;
    min-height: var(--size-navigation-size-sidebar-compact, 76px);
    padding-bottom: var(--space-space-60, 20px);
    align-items: center;
    gap: var(--space-space-70, 24px);
    align-self: stretch;
}

.search {
    display: flex;
    width: 320px;
    align-items: center;
    align-self: stretch;
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
    border-radius: var(--Primary, 8px);


}

.searchLogo {
    display: flex;
    padding: 13px;
    align-items: center;
    border-left: 1px solid var(--Base-Stroke-Grey-subtle, #EAE7EC);

}

.searchBar {
    /* display: flex; */
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;
    background: var(--Base-Layers-layer-primary, #FFF);

    border-radius: var(--Primary, 8px);
    border: none;

}

.searchBar::placeholder {
    color: var(--Base-Text-grey-lowemp, #C0BAC7);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

}

.searchBar:focus {

    outline: none;
}

.fieldValueDropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    color: var(--Base-Text-grey-highemp, #211F26);

    /* Body/Body 3 */
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
    letter-spacing: 0.064px;

    display: flex;
    padding: 13px 16px;
    align-items: center;
    flex: 1 0 0;

    background: var(--Base-Layers-layer-primary, #FFF);
    outline: none;

    border-radius: var(--Primary, 8px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 0px solid var(--Base-Stroke-Grey-low, #DCD8E0);
    border-right: 0;
}

.dropdown {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Primary, 8px);
    border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0);
}

.dropdownArrow {
    display: flex;
    padding: 13px;
    align-items: center;

    border-radius: var(--Primary, 8px);
    /* border: 1px solid var(--Base-Stroke-Grey-low, #DCD8E0); */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}

.rightActions{
    display: flex;
justify-content: flex-end;
align-items: center;
gap: var(--space-space-50, 16px);
flex: 1 0 0;
align-self: stretch;
}

.addNewMemberBorder{
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
   
    border-radius: var(--Primary, 8px);
    background: linear-gradient(264deg, rgba(87, 239, 195, 0.80) 0%, rgba(193, 127, 242, 0.80) 58.11%, rgba(255, 178, 0, 0.80) 100%), var(--Muted-Old-950, #F5F3F6);;
}

.addNewMemberBorder :hover{
    border-radius: var(--Primary, 8px);
    box-shadow: 0px 2px 12px 0px var(--Violet-Alpha-500, rgba(170, 105, 222, 0.48));
}

.addNewMemberStyle{
    display: flex;
padding: 13px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: var(--Primary, 8px);
background-color: white;
    
}

.addNewPracticeFormStepOne{
    z-index: 101;
}

.formBackground{
    overflow:scroll;
    position: fixed;
    background: rgba(3, 3, 3, 0.4);
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-shrink: 0;
    display:flex;
    justify-content: center;
}