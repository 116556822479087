.container{
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
}

.innerContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.mainContent{
    color: var(--Base-Text-grey-midemp, #66636D);
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.iconTypo{
    width: 16px;
    height: 16px;
}