.container{
    display: flex;
    align-items: center;
    align-self: stretch;
    background: var(--Muted-White, #FFF);

    width: 100%;
}

.outerContainer{
    display: flex;
    align-items: center;
    align-self: stretch;
}

.dateItem{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    border-radius: var(--Secondary, 4px) var(--Secondary, 4px) 0px 0px;
    color: var(--Base-Text-grey-midemp, #66636D);
    border-bottom: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
    cursor: pointer;
}

.dateItem:hover{
    color: var(--Base-Text-grey-highemp, #211F26) !important ;
}

.dateContent{
    font-family: "Founders Grotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.064px;
}

.alertIcon{
    width: 22px;
    height: 22px;
}

.activeContent{
    color: var(--Base-Text-brand-primary, #854DC4);
}

.activeDate{
    border-radius: var(--Secondary, 4px) var(--Secondary, 4px) 0px 0px;
    border-top: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
    border-right: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
    border-left: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
    background: var(--Base-Components-Violet-subtle, #FCF8FF);
    border-bottom: none;
}

.borderContainer{
    max-width: 24px;
    min-width: 24px;
    min-height: 39.5px;
    border-bottom: 1px solid var(--Base-Stroke-Brand-high, #C899EC);
}