.container{
    width: 100%;
    height: 100%;
    display : flex;
    flex-direction: column;
}

.upperHeader{
    display: flex;
    padding: var(--Secondary, 4px) var(--Container, 16px);
    align-items: center;
    align-self: stretch;

    background: var(--Base-Layers-layer-primary, #FFF);

    color: var(--Base-Text-brand-primary, #854DC4);
    font-family: "Founders Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.084px;

    cursor: pointer;
}

.mainContainer{
    width: 100%;
    height: auto;
    display : flex;
    flex-direction: column;
}

.backIcon{
    width: 24px;
    height: 24px;
}

.tabOptions{
    display: flex;
    padding: 0px 24px;
    padding-top: 16px;
    align-items: center;
    align-self: stretch;
    width: 100%;
    height: auto;

    border-bottom: 1px solid #C899EC;
    background-color: #FFF;

    position: sticky;
    top: 64px;
    z-index: 100;
}